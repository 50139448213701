import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../auth/useAuth";

const useChangePassword = () => {
  const history = useHistory();
  const { user, updatePassword, reauthenticateWithCredential } = useAuth();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState<string | null>(
    null
  );
  const [changePasswordSuccess, setChangePasswordSuccess] = useState<
    string | null
  >(null);

  useEffect(() => {
    const isPasswordAuthenticated =
      user?.providerData[0]?.providerId === "password";
    if (!isPasswordAuthenticated) {
      history.replace("/settings");
    }
  }, [user?.providerData, history]);

  const handleCurrentPasswordChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleNewPasswordConfirmationChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNewPasswordConfirmation(event.target.value);
  };

  const changePassword = () => {
    setChangePasswordError("");
    setChangePasswordSuccess("");
    if (newPassword !== newPasswordConfirmation) {
      setChangePasswordError("The password confirmation does not match.");
    } else {
      setIsChangingPassword(true);
      (reauthenticateWithCredential(currentPassword) as Promise<void>)
        .then(handleUpdatePassword)
        .catch(() => {
          setChangePasswordError("The current password is invalid.");
          setIsChangingPassword(false);
        });
    }
  };

  const handleUpdatePassword = () => {
    (updatePassword(newPassword) as Promise<void>)
      .then(() => setChangePasswordSuccess("Password changed successfully."))
      .catch((error) => setChangePasswordError(error.message))
      .finally(() => setIsChangingPassword(false));
  };

  return {
    currentPassword,
    handleCurrentPasswordChange,
    newPassword,
    handleNewPasswordChange,
    newPasswordConfirmation,
    handleNewPasswordConfirmationChange,
    changePassword,
    isChangingPassword,
    changePasswordError,
    changePasswordSuccess,
  };
};

export default useChangePassword;
