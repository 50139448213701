import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Request from '../../definitions/Request';
import useAuth from '../../hooks/auth/useAuth';
import {
    addPhotosToRequest,
    deletePhotoFromRequest,
    getDocumentQuery,
    getRequests,
    listenUserSettings,
    postRequestPhotosToJobQueue,
} from '../../utils/firebase';
import usePagination from '../pagination/usePagination';
import Location from '../../definitions/Location';
import { isNullOrUndefined } from 'util';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import loadImage from 'blueimp-load-image';
import { fileToArrayBuffer } from '../../utils/Converters';
import { setPhotoForRequest } from '../../utils/IndexDb';

const useRequests = () => {
    const { user } = useAuth();
    const { numberOfItems, isEndOfList, setIsEndOfList, handleLoadMore, scrollToPreviousPosition } = usePagination();
    const [requests, setRequests] = useState<Request[]>([]);
    const [siteID, setSiteID] = useState('');
    const [locationID, setLocationID] = useState('');
    const [sublocationID, setSubLocationID] = useState('');
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [selectedSubLocation, setSelectedSubLocation] = useState<Sublocation | null>(null);
    const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
    const [collapsedRequestID, setCollapsedRequestID] = useState('');
    const [requestPhotos, setRequestPhotos] = useState<RequestPhoto[]>([]);
    const [isPhotosLoading, setIsPhotosLoading] = useState(false);

    useEffect(() => {
        const unsubscribe = listenUserSettings(user?.uid).onSnapshot(snap => {
            const usersettings = snap.docs[0].data() as UserSettings;
            setUserSettings(usersettings);
        });

        return () => unsubscribe();
    }, [user?.uid]);

    const onSuccess = (result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
        if (result.docs.length < numberOfItems) {
            setIsEndOfList(true);
        }
        const requests = result.docs.map(doc => {
            const request = doc.data();
            request.ID = doc.id;

            return request as Request;
        });
        setRequests(requests);
    };
    const { isLoading, isError, error, refetch } = useQuery(
        [
            `${user?.ClientUID}-${siteID}-${locationID}-${sublocationID}-${userSettings?.CanViewAllRequests}-requests`,
            numberOfItems,
        ],
        () =>
            getRequests(numberOfItems, siteID, locationID, sublocationID, userSettings?.CanViewAllRequests, user?.uid),
        { onSuccess, cacheTime: 0 },
    );

    const searchFunction = (values: any) => {
        setSiteID(values.SiteID);
        setSelectedSite(values.Site);
        setLocationID(values.LocationID);
        setSelectedLocation(values.Location);
        setSubLocationID(values.SubLocationID);
        setSelectedSubLocation(values.SubLocation);
    };

    const clearFilter = () => {
        setSelectedSite(null);
        setSelectedLocation(null);
        setSelectedSubLocation(null);
    };

    const getPhotosForRequest = (requestFBID: string) => {
        if (isNullOrUndefined(requestFBID) || requestFBID == '') return;

        setIsPhotosLoading(true);
        const requestQuery = getDocumentQuery('Requests', requestFBID).get();
        const photosQuery = getDocumentQuery('Requests', requestFBID)
            .collection('Documents')
            .get();

        Promise.all([requestQuery, photosQuery]).then(([requestDocument, photosDocuments]) => {
            const photos = photosDocuments.docs.map(doc => {
                return { ID: doc.id, ...doc.data() };
            });
            setRequestPhotos(photos as RequestPhoto[]);
            setIsPhotosLoading(false);
        });
    };

    useEffect(() => {
        getPhotosForRequest(collapsedRequestID);
    }, [collapsedRequestID]);

    const saveImage = async (selectorFiles: FileList | null, requestFBID: string | null) => {
        if (requestFBID == '' || isNullOrUndefined(requestFBID)) return;

        if (selectorFiles !== null) {
            for (let i = 0; i < selectorFiles.length; i++) {
                const photo: Blob = selectorFiles[i];
                const CompressionRatio = GetFileCompressionRatio(photo);

                loadImage(
                    photo,
                    (canvas: HTMLCanvasElement | HTMLImageElement | any) => {
                        canvas.toBlob(async (blob: Blob) => {
                            const arrayBuffer = await fileToArrayBuffer(blob as Blob);
                            const guid = await setPhotoForRequest(arrayBuffer, null);
                            const blobUrl = URL.createObjectURL(blob);
                            if (!navigator.onLine) {
                                const localImage: RequestPhoto = { FirebaseStoragePath: blobUrl };
                                setRequestPhotos((prevState) => [...prevState, localImage]);
                            }
                            const uploadPhotosLinks = await addPhotosToRequest(guid, requestFBID);                           
                            const newPhotosArray: RequestPhoto[] = [];
                            const newPhotosFBIDArray: string[] = [];                           
                            for (const link of uploadPhotosLinks) {
                                if (!link) {
                                    continue;
                                }                             
                                const newPhoto: RequestPhoto = {
                                    FirebaseStoragePath: link,
                                    DateCreated: Date.now(),
                                    Guid: guid,
                                    UploadedBy: user?.email || user?.id,
                                    UploadedDate: Date.now(),
                                };
                                const newDoc = await getDocumentQuery('Requests', requestFBID)
                                    .collection('Documents')
                                    .add(newPhoto);

                                newPhotosArray.push({ ID: newDoc.id, ...newPhoto });
                                newPhotosFBIDArray.push(link + "/*/" + newDoc.id);
                            }
                            setRequestPhotos(prevState => [...prevState, ...newPhotosArray]);
                            postRequestPhotosToJobQueue(
                                uploadPhotosLinks,
                                requestFBID,
                                guid,
                                newPhotosFBIDArray
                            );
                        });
                    },
                    {
                        maxWidth: 400,
                        maxHeight: 400,
                        orientation: true,
                        downsamplingRatio: CompressionRatio,
                        canvas: true
                    },
                );
            }
        }
    };

    const deleteImage = async (photo: RequestPhoto, requestFBID: string) => {
        if (photo.PhotoFBID == null) photo.PhotoFBID = photo.ID;

        await deletePhotoFromRequest(photo.PhotoFBID, requestFBID);

        getPhotosForRequest(requestFBID);
    };

    return {
        requests,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore,
        searchFunction,
        selectedSite,
        clearFilter,
        selectedLocation,
        selectedSubLocation,
        collapsedRequestID,
        setCollapsedRequestID,
        requestPhotos,
        isPhotosLoading,
        saveImage,
        deleteImage,
    };
};

export default useRequests;
