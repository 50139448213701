import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockIcon from "@material-ui/icons/Lock";
import { Link } from "react-router-dom";
import useSettings from "../../hooks/settings/useSettings";
import "../../styles/settings/settings.css";
import {version} from '../../../package.json';
import { getLanguages } from "../../utils/firebase";
import AsyncSelect from "../../components/shared/AsyncSelect";
import { useTranslation } from 'react-i18next';


const Settings = () => {
  const { user, isPasswordAuthenticated, selectedLanguage, handleSignout, handleSelectedLanguageChange } = useSettings();
  const { t, i18n } = useTranslation();
  return (
    <div className="settings-main-container">
      <Card>
        <CardContent className="settings-card-content">
          <Typography
            noWrap
            variant="h5"
            component="h2"
            className="settings-card-content-title"
          >
            {user?.displayName}
          </Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ noWrap: true }}>
                {user?.email}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ noWrap: true }}>
                {t("Version") + " " + version}
              </ListItemText>
            </ListItem>
          </List>
          <Grid item className="async-select-container">
          <AsyncSelect
            label={t('Languages')} 
            nameProperty="LanguageLabel"
            selectedOption={selectedLanguage}
            handleSelectedOptionChange={handleSelectedLanguageChange}
            loadFunction={getLanguages}
          />
        </Grid>
        </CardContent>
      </Card>
      {isPasswordAuthenticated && (
        <Link to="/settings/change-password" className="change-password-link">
          <Button
            variant="contained"
            fullWidth
            className="change-password-button"
            endIcon={<LockIcon />}
          >
            {t("Change Password")}
          </Button>
        </Link>
      )}
      <Button
        color="secondary"
        variant="contained"
        fullWidth
        className="logout-button"
        onClick={handleSignout}
        endIcon={<ExitToAppIcon />}
      >
        {t("Logout")}
      </Button>
    </div>
  );
};

export default Settings;
