import firebase from "firebase/app";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import Contract from "../../definitions/Contract";
import Region from "../../definitions/Region";
import SiteType from "../../definitions/SiteType";
import useAuth from "../../hooks/auth/useAuth";
import Algolia from "../../utils/algolia";
import {getSites, getUserSettings, listenUserSettings} from "../../utils/firebase";
import usePagination from "../pagination/usePagination";


const useSites = () => {
    const {user} = useAuth();
    const {numberOfItems, isEndOfList, setIsEndOfList, handleLoadMore, scrollToPreviousPosition} = usePagination();
    const [collapsedSiteID,
        setCollapsedSiteID] = useState("");
    const [siteTypeID,
        setSiteTypeID] = useState("");
    const [regionID,
        setRegionID] = useState("");
    const [contractID,
        setContractID] = useState("");
    const [sites,
        setSites] = useState < Site[] > ([]);
    const [selectedSiteType,
        setSelectedSiteType] = useState < SiteType | null > (null);
    const [selectedRegion,
        setSelectedRegion] = useState < Region | null > (null);
    const [selectedContract,
        setSelectedContract] = useState < Contract | null > (null);
    const [textSearchActive, setTextSearchActive] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [userSettings,
        setUserSettings] = useState < UserSettings | null > (null);

    useEffect(scrollToPreviousPosition, [sites, scrollToPreviousPosition]);

    useEffect(() => {
        const unsubscribe = listenUserSettings(user?.uid).onSnapshot(snap => {
            const usersettings = snap.docs[0].data() as UserSettings
            setUserSettings(usersettings)
        });
     
        return () => unsubscribe()
     }, []);

    const onSuccess = async (result : firebase.firestore.QuerySnapshot < firebase.firestore.DocumentData > | null) => {   
        if(result != null){ 
            if (result.docs.length < numberOfItems) {
                setIsEndOfList(true);
            }
            const sites = result
                .docs
                .map((doc) => {
                    const site = doc.data();
                    site.ID = doc.id;
                    return site as Site;
                });
            setSites(sites);          
        }

        else {
            //Algolia.
            const algolia =  new Algolia();
            let searchResults = await algolia.sitesSearch(textSearch, user?.ClientUID, {
                ClientFBID: user?.ClientUID, SiteTypeFBID: siteTypeID, RegionFBID: regionID,
                ContractFBID: contractID
            });

            if (searchResults.length < numberOfItems) {
                setIsEndOfList(true);
            }

            searchResults = searchResults.map((searchResult: Site) => ({ ...searchResult, ID: searchResult.SiteFBID }));
            setSites(searchResults);
        }
        
    };

    const {isLoading, isError, error, refetch} = useQuery([
        `${user
            ?.ClientUID}-${siteTypeID}-${regionID}-${contractID}-${textSearch}-${textSearchActive}-${userSettings?.CanAccessAllSites}-sites`,
        numberOfItems
    ], () => 
         getSites(numberOfItems, siteTypeID, regionID, contractID, textSearchActive, userSettings?.CanAccessAllSites, user?.uid), {onSuccess, cacheTime: 0}
        );

    const searchFunction = (values : any) => {
        if(values.TextSearch !== null && values.TextSearch != "" && values.TextSearch !== undefined && values.ClearFilters != true){
            setTextSearchActive(true);
            setTextSearch(values.TextSearch);
        }

        setSelectedSiteType(values.SiteType);
        setSiteTypeID(values.SiteTypeID);
        setSelectedRegion(values.Region);
        setRegionID(values.RegionID);
        setSelectedContract(values.Contract);
        setContractID(values.ContractID);
    };

    const clearFilter = () => {
        setTextSearchActive(false);
        setSelectedSiteType(null);
        setSelectedContract(null);
        setSelectedRegion(null);
        setTextSearch("");
    }

    return {
        collapsedSiteID,
        setCollapsedSiteID,
        sites,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore,
        searchFunction,
        selectedSiteType,
        selectedContract,
        selectedRegion,
        clearFilter,
        textSearchActive,
        textSearch
    };
};

export default useSites;
