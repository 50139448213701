import { useTranslation } from "react-i18next";
import useAssetPaginate from "../../hooks/async-paginate/useAssetPaginate";
import { AsyncPaginate } from 'react-select-async-paginate';
import '../../styles/new-request/new-request.css';
import { Icon, IconButton, InputLabel } from "@material-ui/core";

type AsyncAssetProps = {
  handleSelectedAssetChange: (newOption: any) => void;
  siteID: string;
  locationID: string;
  sublocationID: string;
  clientFBID: string;
  selectedAsset: Asset | null;
};

const AsyncAssetDropdown = ({handleSelectedAssetChange, siteID, locationID, sublocationID, clientFBID, selectedAsset}: AsyncAssetProps) => {
  const {
    loadAssets,
    assetFilter,
    assetRef,
    openAssetMenu,
    closeAssetMenu,
    assetMenuOpen,
    handleAssetChange,
    showKeyboard,
    assetValue,
  } = useAssetPaginate(handleSelectedAssetChange, siteID, locationID, sublocationID, clientFBID, selectedAsset);
  const { t } = useTranslation();

  return (
    <>
    <InputLabel style={{ marginBottom: 7, display: 'flex' }}>{t("Asset")}</InputLabel>
    <div className="select-container">
    <div className="select-sub-container">
    <AsyncPaginate
        isRequired={true}
        required={true}
        id="asset-helper"
        value={assetFilter}
        selectRef={assetRef}
        onFocus={openAssetMenu}
        onBlur={closeAssetMenu}
        menuIsOpen={assetMenuOpen}
        onChange={handleAssetChange}
        blurInputOnSelect={true}
        loadOptions={loadAssets}
        loadingMessage={() => t("Loading Assets...")}
        noOptionsMessage={() => t("No Assets Found")}
        cacheUniqs={[assetFilter, assetValue, siteID, locationID, sublocationID]}
        isSearchable={ true }
								/>
  </div>
  {assetFilter != null ? <div className="clear-button-container">
  <div>
    <IconButton onClick={() => handleAssetChange(null)} className="clear-button">
      <Icon color="error">delete_forever</Icon>
    </IconButton>
  </div>
</div> : null}
  </div>
  </>
  );
};

export default AsyncAssetDropdown;
