import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LocationCard from "../../components/locations/LocationCard";
import ErrorDisplay from "../../components/shared/ErrorDisplay";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import NoResults from "../../components/shared/NoResults";
import PaginationButton from "../../components/shared/PaginationButton";
import useLocations from "../../hooks/locations/useLocations";

const Locations = () => {
  const {
    collapsedLocationID,
    setCollapsedLocationID,
    locations,
    isLoading,
    isError,
    error,
    refetch,
    isEndOfList,
    handleLoadMore,
  } = useLocations();
  const { t, i18n } = useTranslation();

  if (isLoading) {
    return <LoadingSpinner text={t("Loading Locations")} />;
  }

  if (isError) {
    return (
      <ErrorDisplay errorMessage={(error as any).message} retry={refetch} />
    );
  }

  if (locations.length === 0) {
    return <NoResults itemName={t("Locations")} />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {locations.map((location) => (
        <Grid
          item
          key={location.ID}
          onClick={() =>
            setCollapsedLocationID(
              location.ID === collapsedLocationID ? "" : location.ID
            )
          }
        >
          <LocationCard
            location={location}
            isCollapseOpen={location.ID === collapsedLocationID}
          />
        </Grid>
      ))}
      <PaginationButton
        width="75vw"
        isEndOfList={isEndOfList}
        itemName={t("Locations")}
        handleLoadMore={handleLoadMore}
      />
    </Grid>
  );
};

export default Locations;
