import { Button, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import RefreshIcon from "@material-ui/icons/Refresh";
import "../../styles/shared/error-display.css";

type ErrorDisplayProps = {
  errorMessage: string;
  retry: () => void;
};

const ErrorDisplay = ({ errorMessage, retry }: ErrorDisplayProps) => (
  <div className="error-display-container">
    <ErrorIcon className="error-display-icon" color="secondary" />
    <Typography
      align="center"
      variant="h2"
      color="primary"
      className="error-display-title"
    >
      An unexpected error occured
    </Typography>
    <Typography align="center" color="error" className="error-display-text">
      {errorMessage}
    </Typography>
    <Button
      color="secondary"
      variant="contained"
      onClick={retry}
      className="retry-button"
      endIcon={<RefreshIcon />}
    >
      Try again
    </Button>
  </div>
);

export default ErrorDisplay;
