import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/auth/useAuth";
import { getSublocationsOfLocation } from "../../utils/firebase";
import usePagination from "../pagination/usePagination";

const useSublocations = () => {
  const { user } = useAuth();
  const { locationID } = useParams<{ locationID: string }>();
  const {
    numberOfItems,
    isEndOfList,
    setIsEndOfList,
    handleLoadMore,
    scrollToPreviousPosition,
  } = usePagination();
  const [collapsedSublocationID, setCollapsedSublocationID] = useState("");
  const [sublocations, setSublocations] = useState<Sublocation[]>([]);

  useEffect(scrollToPreviousPosition, [sublocations, scrollToPreviousPosition]);

  const onSuccess = (
    result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) => {
    if (result.docs.length < numberOfItems) {
      setIsEndOfList(true);
    }
    const sublocations = result.docs.map((doc) => {
      const sublocation = doc.data();
      sublocation.ID = doc.id;
      return sublocation as Sublocation;
    });
    setSublocations(sublocations);
  };

  const { isLoading, isError, error, refetch } = useQuery(
    [`${user?.ClientUID}-${locationID}-sublocations`, numberOfItems],
    () => getSublocationsOfLocation(locationID, numberOfItems),
    { onSuccess, cacheTime: 0 }
  );

  return {
    collapsedSublocationID,
    setCollapsedSublocationID,
    sublocations,
    isLoading,
    isError,
    error,
    refetch,
    isEndOfList,
    handleLoadMore,
  };
};

export default useSublocations;
