import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import useAsyncSelect from "../../hooks/async-select/useAsyncSelect";

type AsyncSelectProps = {
  label: string;
  nameProperty: string;
  dependsOnID?: string | null;
  dependsOnMultipleIDs?: object | null
  disabledOnID?: string | null;
  isRequired?: boolean;
  selectedOption: any;
  handleSelectedOptionChange: (newOption: any) => void;
  loadFunction: (
    ...args: any[]
  ) => Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  >;
  userSettings?: UserSettings
};

const AsyncSelect = ({
  label,
  nameProperty,
  dependsOnID,
  disabledOnID,
  isRequired,
  selectedOption,
  handleSelectedOptionChange,
  loadFunction,
  dependsOnMultipleIDs,
  userSettings
}: AsyncSelectProps) => {
  const {
    options,
    isLoading,
    isOpen,
    handleOpen,
    handleClose,
    handleOptionChange,
  } = useAsyncSelect(
    label,
    handleSelectedOptionChange,
    loadFunction,
    dependsOnID,
    dependsOnMultipleIDs,
    userSettings
  );
  const { t } = useTranslation();
  
  return (
    <Autocomplete
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      disabled={disabledOnID === null}
      loading={isLoading}
      //loadingText={`Loading ${label}s ...`}
      loadingText={t('Loading {{l}}' + '...', { l: label })}
      noOptionsText={t('No {{l}}s to show', { l: label })}
      value={selectedOption}
      options={options}
      getOptionSelected={(option: any, value) => option.ID === value.ID}
      getOptionLabel={(option: any) => option[nameProperty] ?? "N/A"}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          required={isRequired}
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="secondary" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      ListboxProps={{ style: { maxHeight: 150 } }}
    />
  );
};

export default AsyncSelect;
