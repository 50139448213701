import { Button, TextField, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { FormEvent } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import CheckingDetailsSpinner from "../../components/shared/CheckingDetailsSpinner";
import useLogin from "../../hooks/login/useLogin";
import "../../styles/login/login.css";

const Login = () => {
  const {
    email,
    password,
    handleEmailChange,
    handlePasswordChange,
    handleLoginWithMicrosoft,
    login,
    isLoginIn,
    isLoginError,
    loginError,
    isClientExpired,
    isResourceNotFound,
    redirected,
    loginImage
  } = useLogin();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login();
  };

  if (isLoginIn) {
    return <CheckingDetailsSpinner />;
  }

  return (
    <div className="main-container">
      <div className="login-form-container">
        <img src={loginImage} alt="Trackplan logo" className="logo" />
        <Typography color="primary" variant="h1" className="app-title">
          Request App
        </Typography>
        <form onSubmit={handleSubmit} className="login-form">
          <TextField
            required
            id="email"
            type="email"
            label="Email"
            margin="normal"
            className="login-textfield"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            required
            id="password"
            type="password"
            label="Password"
            margin="normal"
            className="login-textfield"
            value={password}
            onChange={handlePasswordChange}
          />
          {isClientExpired && (
            <Typography
              className="login-error-message"
              variant="subtitle2"
              color="error"
              align="justify"
            >
              The client has expired. Please contact your administrator to re-enable your account.
            </Typography>
          )}
          {isResourceNotFound && (
            <Typography
              className="login-error-message"
              variant="subtitle2"
              color="error"
              align="justify"
            >
              Resource not found. Please check login details and try again.
            </Typography>
          )}
          {isLoginError && (
            <Typography
              className="login-error-message"
              variant="subtitle2"
              color="error"
              align="justify"
            >
              {(loginError as { message: string })?.message}
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="login-button"
          >
            Login
          </Button>
          <Typography className="or-text">OR</Typography>
          <Button
            variant="contained"
            className="microsoft-login-button"
            onClick={handleLoginWithMicrosoft}
          >
            Login With Microsoft
          </Button>
          <Link to="/forgotten-password" className="forgotten-password-link">
            <Button
              color="secondary"
              className="forgotten-password-button"
              endIcon={<HelpIcon />}
            >
              Forgotten Password
            </Button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
