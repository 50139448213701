import { Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation, withTranslation } from "react-i18next";
import "../../styles/shared/no-results.css";

type NoResultsProps = {
  itemName: string;
};

const NoResults = ({ itemName }: NoResultsProps) => {
  const { t } = useTranslation(); 
  return (
  <div className="no-results-container">
    <VisibilityIcon className="no-results-icon" color="secondary" />
    <Typography
      align="center"
      variant="h2"
      color="primary"
      className="no-results-text"
    >
      {t("There are no") + " " + itemName + " " + t("to show.")}
    </Typography>
  </div>
  )};

export default NoResults;
