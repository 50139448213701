import firebase from "firebase/app";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import useAuth from "../../hooks/auth/useAuth";
import {getAssets} from "../../utils/firebase";
import usePagination from "../pagination/usePagination";
import Location from "../../definitions/Location";
import AssetClass from "../../definitions/AssetClass";
import Algolia from "../../utils/algolia";

const useAssets = () => {
    const {user} = useAuth();
    const {numberOfItems, isEndOfList, setIsEndOfList, handleLoadMore, scrollToPreviousPosition} = usePagination();
    const [assets,
        setAssets] = useState < Asset[] > ([]);
    const [siteID,
        setSiteID] = useState("");
    const [locationID,
        setLocationID] = useState("");
    const [sublocationID,
        setSubLocationID] = useState("");
    const [assetClassID,
        setAssetClassID] = useState("");
    const [subClassID,
        setSubClassID] = useState("");
    const [selectedSite,
        setSelectedSite] = useState < Site | null > (null);
    const [selectedLocation,
        setSelectedLocation] = useState < Location | null > (null);
    const [selectedSubLocation,
        setSelectedSubLocation] = useState < Sublocation | null > (null);
    const [selectedAssetClass,
        setSelectedAssetClass] = useState < AssetClass | null > (null);
    const [selectedSubClass,
        setSelectedSubClass] = useState < AssetClass | null > (null);
    const [collapsedAssetID,
        setCollapsedAssetID] = useState("");
    const [textSearchActive, setTextSearchActive] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    

    useEffect(scrollToPreviousPosition, [assets, scrollToPreviousPosition]);

    const onSuccess = async (result : firebase.firestore.QuerySnapshot < firebase.firestore.DocumentData >) => {
        if(result != null){
        if (result.docs.length < numberOfItems) {
            setIsEndOfList(true);
        }
        const assets = result
            .docs
            .map((doc) => {
                const asset = doc.data();
                asset.ID = doc.id;
                return asset as Asset;
            });
        setAssets(assets);
    }

    else {
        const algolia =  new Algolia();
        let searchResults = await algolia.assetsSearch(textSearch, user?.ClientUID, {
            ClientFBID: user?.ClientUID, Site: selectedSite?.SiteID, Location: selectedLocation?.LocationID,
            Sublocation: selectedSubLocation?.SubLocationID, AssetClass: assetClassID, AssetSubClass: subClassID
        });

        if (searchResults.length < numberOfItems) {
            setIsEndOfList(true);
        }

        searchResults = searchResults.map((searchResult: Asset) => ({ ...searchResult, ID: searchResult.AssetFBID }));
        setAssets(searchResults);
    }    
}

    const {isLoading, isError, error, refetch} = useQuery([
        `${user
            ?.ClientUID}-${siteID}-${locationID}-${sublocationID}-${assetClassID}-${subClassID}-${textSearch}-${textSearchActive}-assets`,
        numberOfItems
    ], () => getAssets(numberOfItems, siteID, locationID, sublocationID, assetClassID, subClassID, textSearchActive), {onSuccess, cacheTime: 0});

    const clearFilter = () => {
        setTextSearchActive(false);
        setSelectedSite(null);
        setSelectedLocation(null); 
        setSelectedSubLocation(null);
        setSelectedAssetClass(null);
        setSelectedSubClass(null);
        setTextSearch("");
    }

    const searchFunction = (values : any) => {
        if(values.TextSearch !== null && values.TextSearch != "" && values.TextSearch !== undefined && values.ClearFilters != true){
            setTextSearchActive(true);
            setTextSearch(values.TextSearch);
        }

        setSiteID(values.SiteID);
        setSelectedSite(values.Site);
        setLocationID(values.LocationID);
        setSelectedLocation(values.Location)
        setSubLocationID(values.SubLocationID);
        setSelectedSubLocation(values.SubLocation);
        setSelectedAssetClass(values.AssetClass);
        setAssetClassID(values.AssetClassID);
        setSelectedSubClass(values.SubClass);
        setSubClassID(values.SubClassID);
    };

    return {
        assets,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore,
        searchFunction,
        clearFilter,
        selectedSite,
        selectedLocation,
        selectedSubLocation,
        selectedAssetClass,
        selectedSubClass,
        setCollapsedAssetID,
        collapsedAssetID,
        textSearchActive,
        textSearch
    };
};

export default useAssets;
