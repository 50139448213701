import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@material-ui/core";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import AsyncSelect from "../../components/shared/AsyncSelect";
import AsyncPaginateDropdown from "../../components/shared/AsyncPaginateDropdown";
import RequestPhotos from "../../components/shared/RequestPhotos";
import useNewRequest from "../../hooks/new-request/useNewRequest";
import "../../styles/new-request/new-request.css";
import {
  getAssetsForDropdowns,
  getJobSubTypes,
  getJobTypes,
  getLocationsOfSiteDropdown,
  getSitesDropdown,
  getSublocationsOfLocationDropdown,
} from "../../utils/firebase";
import { useTranslation } from 'react-i18next';
import FieldEmptyWarning from "../../components/shared/ErrorHandling/FieldEmptyWarning";
import AsyncLocationDropdown from "../../components/shared/AsyncLocationDropdown";
import AsyncSublocationDropdown from "../../components/shared/AsyncSublocationDropdown";
import AsyncAssetDropdown from "../../components/shared/AsyncAssetDropdown";
import useVersionCheck from "../../hooks/auth/useVersionCheck";

const NewRequest = () => {
  const {
    isLoading,
    details,
    selectedAsset,
    selectedJobType,
    selectedJobSubType,
    selectedSite,
    selectedLocation,
    selectedSublocation,
    handleDetailsChange,
    handleSelectedAssetChange,
    handleSelectedJobTypeChange,
    handleSelectedJobSubTypeChange,
    handleSelectedSiteChange,
    handleSelectedSiteChangeLazy,
    handleSelectedLocationChange,
    handleSelectedSublocationChange,
    handleSubmit,
    openImageDialog,
    saveImage,
    handleRaisedByChange,
    handleEmailChange,
    clientSettings,
    isLocationsForSite,
    requestPhotos,
    raisedBy,
    email,
    getPhotos,
    guid,
    userSettings
  } = useNewRequest();
  const { t, i18n } = useTranslation();
  //useVersionCheck();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className="new-request-container"
    >
      <Avatar className="new-request-avatar">
        <NewReleasesIcon className="new-request-avatar-icon" />
      </Avatar>
      <Typography component="h1" variant="h5" className="new-request-title">
      {t('Raise new request')}  
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid item>
          <TextField
            multiline
            rows={3}
            variant="outlined"
            required
            className="new-request-details"
            label={t('Details')} 
            autoFocus
            value={details}
            onChange={handleDetailsChange}
          />
        </Grid>
        {clientSettings?.AssetEnabled || typeof selectedAsset?.ID === 'string' ? 
        <Grid item className="async-select-container">
        <AsyncAssetDropdown handleSelectedAssetChange={handleSelectedAssetChange} clientFBID=""
         siteID={selectedSite?.SiteFBID || ""} locationID={selectedLocation?.ID || ""} sublocationID={selectedSublocation?.ID || ""}
         selectedAsset={selectedAsset} />  
      </Grid>   
 : null}     
        <Grid item className="async-select-container">
          <AsyncPaginateDropdown handleSelectedSiteChange={handleSelectedSiteChange} selectedSite={selectedSite} />         
        </Grid>
        <Grid item className="async-select-container">
          <AsyncLocationDropdown handleSelectedLocationChange={handleSelectedLocationChange} selectedSiteID={selectedSite?.SiteID || ""} selectedLocation={selectedLocation} />         
        </Grid>
        <Grid item className="async-select-container">
          <AsyncSublocationDropdown handleSelectedSublocationChange={handleSelectedSublocationChange} selectedLocationID={selectedLocation?.LocationID || ""} 
          selectedSublocation={selectedSublocation}/>  
        </Grid>       
        {clientSettings?.JobTypeEnabled ? <><Grid item className="async-select-container">
          <div className="async-select-container-type">
          <AsyncSelect
            isRequired={clientSettings?.JobTypeRequired ? true : false}
            label={t('Job type')} 
            nameProperty="JobTypeName"
            selectedOption={selectedJobType}
            handleSelectedOptionChange={handleSelectedJobTypeChange}
            loadFunction={getJobTypes}
            userSettings={userSettings}
          />
          </div>
        </Grid>
        <Grid item className="async-select-container-type">
          <AsyncSelect
            label={t('Job sub-type')} 
            nameProperty="JobSubTypeName"
            dependsOnID={selectedJobType?.ID || null}
            disabledOnID={selectedJobType?.ID || null}
            selectedOption={selectedJobSubType}
            handleSelectedOptionChange={handleSelectedJobSubTypeChange}
            loadFunction={getJobSubTypes}
          />
        </Grid></> : null}
        <Grid item>
            <TextField
              variant="outlined"
              required
              className="guest-request-textfields"
              label={t('Raised By')} 
              value={raisedBy}
              onChange={handleRaisedByChange}
            />
          </Grid>
          <Grid item>
            <TextField
              type="email"
              variant="outlined"
              required
              className="guest-request-textfields"
              label={t('Email')} 
              value={email}
              onChange={handleEmailChange}
            />
</Grid>
        <Grid item>
          <input
            id="image-upload"
            type="file"
            multiple
            onChange={e => saveImage(e.target.files)}
            style={{ display: 'none' }}
          />
          </Grid>
            <RequestPhotos photos={requestPhotos} getPhotos={getPhotos} guid={guid}/>
            <Grid item>
            <Button variant="contained" color="primary" className="add-photo-button" onClick={() => openImageDialog()}>
                <Icon>add_circle</Icon>&nbsp; {t('Add Photo')} 
                </Button>
            </Grid>
        <Button
          type="submit"
          className="submit-new-request-button"
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            t('Raise request')
          )}
        </Button>
      </form>
    </Grid>
  );
};

export default NewRequest;
