import { Box, Grid } from '@material-ui/core';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import NoResults from '../../components/shared/NoResults';
import PaginationButton from '../../components/shared/PaginationButton';
import SearchFilter from '../../components/shared/SearchFilter';
import SiteCard from '../../components/sites/SiteCard';
import useSites from '../../hooks/sites/useSites';
import { useTranslation } from 'react-i18next';

const Sites = () => {
    const {
        collapsedSiteID,
        setCollapsedSiteID,
        sites,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore,
        searchFunction,
        selectedSiteType,
        selectedRegion,
        selectedContract,
        clearFilter,
        textSearch,
    } = useSites();
    const { t, i18n } = useTranslation();

    if (isLoading) {
        return <LoadingSpinner text={t('Loading Sites')} />;
    }

    if (isError) {
        return (
            <>
                <ErrorDisplay errorMessage={(error as any).message} retry={refetch} />
                <SearchFilter
                    label={t('Site Search')}
                    searchFunction={searchFunction}
                    clearFilter={clearFilter}
                    siteType={selectedSiteType}
                    region={selectedRegion}
                    contract={selectedContract}
                    assetClass={null}
                    subClass={null}
                    site={null}
                    location={null}
                    sublocation={null}
                    textInput={textSearch}
                    displayTextSearch={true}
                />
            </>
        );
    }

    if (sites.length === 0) {
        return (
            <>
                <NoResults itemName={t('Sites')} />
                <SearchFilter
                    label={t('Site Search')}
                    searchFunction={searchFunction}
                    clearFilter={clearFilter}
                    siteType={selectedSiteType}
                    region={selectedRegion}
                    contract={selectedContract}
                    assetClass={null}
                    subClass={null}
                    site={null}
                    location={null}
                    sublocation={null}
                    textInput={textSearch}
                    displayTextSearch={true}
                />
            </>
        );
    }

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" direction="column">
                {sites.map(site => (
                    <Grid
                        item
                        key={site.ID}
                        style={{ width: '100%' }}
                        onClick={() => setCollapsedSiteID(site.ID === collapsedSiteID ? '' : site.ID)}
                    >
                        <Box marginTop={1}>
                            <SiteCard site={site} isCollapseOpen={site.ID === collapsedSiteID} />
                        </Box>
                    </Grid>
                ))}
                <Grid item>
                    <PaginationButton
                        width="100%"
                        isEndOfList={isEndOfList}
                        itemName={t('Sites')}
                        handleLoadMore={handleLoadMore}
                    />
                </Grid>
            </Grid>
            <SearchFilter
                label={t('Site Search')}
                searchFunction={searchFunction}
                clearFilter={clearFilter}
                siteType={selectedSiteType}
                region={selectedRegion}
                contract={selectedContract}
                assetClass={null}
                subClass={null}
                site={null}
                location={null}
                sublocation={null}
                textInput={textSearch}
                displayTextSearch={true}
            />
        </>
    );
};

export default Sites;
