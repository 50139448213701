import { Button, TextField, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FormEvent } from "react";
import logo from "../../assets/images/logo.png";
import useForgottenPassword from "../../hooks/login/useForgottenPassword";
import "../../styles/login/forgotten-password.css";

const ForgottenPassword = () => {
  const {
    email,
    forgottenPasswordError,
    forgottenPasswordSuccess,
    handleEmailChange,
    handleNavigateBack,
    sendForgottenPasswordLink,
  } = useForgottenPassword();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendForgottenPasswordLink();
  };

  return (
    <div className="main-container">
      <div className="forgotten-password-form-container">
        <img src={logo} alt="Trackplan logo" className="logo" />
        <Typography variant="h1" color="primary" className="enter-email">
          Please enter your email address
        </Typography>
        <form onSubmit={handleSubmit} className="forgotten-password-form">
          <TextField
            required
            id="email"
            type="email"
            label="Email"
            margin="normal"
            className="forgotten-password-email-textfield"
            value={email}
            onChange={handleEmailChange}
          />
          {forgottenPasswordError && (
            <Typography
              className="forgotten-password-result-text"
              variant="subtitle2"
              color="error"
              align="justify"
            >
              {forgottenPasswordError}
            </Typography>
          )}
          {forgottenPasswordSuccess && (
            <Typography
              className="forgotten-password-result-text"
              variant="subtitle2"
              color="primary"
              align="justify"
            >
              {forgottenPasswordSuccess}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="send-email-link-button"
          >
            Send Email Link
          </Button>
          <Button
            color="secondary"
            variant="contained"
            endIcon={<ArrowBackIcon />}
            className="back-button"
            onClick={handleNavigateBack}
          >
            Go back
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ForgottenPassword;
