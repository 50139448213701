import * as React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { unixToDateString } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

interface DocumentDetailsProps {
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
	t: any;
}

 class DocumentDetails extends React.Component<DocumentDetailsProps> {
	render() {
		return (
			<Table size="small">
				<TableBody>
					{this.props.Filename && (
						<TableRow>
							<TableCell>{this.props.t("File Name")}</TableCell>
							<TableCell>{this.props.Filename}</TableCell>
						</TableRow>
					)}

					{this.props.UploadedBy && (
						<TableRow>
							<TableCell>{this.props.t("Uploaded By")}</TableCell>
							<TableCell>{this.props.UploadedBy}</TableCell>
						</TableRow>
					)}

					{this.props.DateCreated && (
						<TableRow>
							<TableCell>{this.props.t("Uploaded On")}</TableCell>
							<TableCell>{unixToDateString(this.props.DateCreated || 0)}</TableCell>
						</TableRow>
					)}

					{this.props.Source && (
						<TableRow>
							<TableCell>{this.props.t("Source")}</TableCell>
							<TableCell>{this.props.Source}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		);
	}
}

export default withTranslation()(DocumentDetails);
