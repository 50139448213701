import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Grid,
    Icon,
    TextField,
    Toolbar,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BuildIcon from '@material-ui/icons/Build';
import CategoryIcon from '@material-ui/icons/Category';
import AdjustIcon from '@material-ui/icons/Adjust';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import RequestPhotos from '../../components/shared/RequestPhotos';
import useGuestRequest from '../../hooks/guest-request/useGuestRequest';
import '../../styles/guest-request/guest-request.css';
import AsyncSelect from '../../components/shared/AsyncSelect';
import { getJobSubTypesGuest, getJobTypesGuest } from '../../utils/firebase';
import { useTranslation } from 'react-i18next';
import AsyncAssetDropdown from '../../components/shared/AsyncAssetDropdown';
import AsyncPaginateDropdown from '../../components/shared/AsyncPaginateDropdown';
import AsyncLocationDropdown from '../../components/shared/AsyncLocationDropdown';
import AsyncSublocationDropdown from '../../components/shared/AsyncSublocationDropdown';
import Reaptcha from 'reaptcha';
import { publicKeyRecaptchaV2 } from '../../configuration.json';

import { LegacyRef } from 'react';
import parse from 'html-react-parser';
const GuestRequest = (): JSX.Element => {
    const {
        isLoading,
        isSubmitting,
        guestData,
        details,
        raisedBy,
        email,
        clientSettings,
        selectedLocation,
        selectedSublocation,
        selectedJobType,
        selectedJobSubType,
        selectedAsset,
        selectedSite,
        requestPhotos,
        handleSubmit,
        handleDetailsChange,
        handleRaisedByChange,
        handleEmailChange,
        openImageDialog,
        handleSelectedLocationChange,
        handleSelectedSublocationChange,
        handleSelectedJobTypeChange,
        handleSelectedJobSubTypeChange,
        handleSelectedAssetChange,
        handleSelectedSiteChange,
        saveImage,
        getPhotos,
        guid,
    } = useGuestRequest();
    const { t } = useTranslation();
    const setAsRequiredRecaptchaContainer: LegacyRef<Reaptcha> = e => {
        // see https://stackoverflow.com/a/37048027
        // a small hack to get the captcha field required 'natively'
        // I did this to keep consistency with the rest of the form, which is also using html5 native validation "required" attributes
        e?.container?.querySelector('#g-recaptcha-response')?.setAttribute('required', 'required');
    };
    
    if (isLoading) {
        return <LoadingSpinner text="Loading" />;
    }

    const guestRequestFields = [
        {
            name: t('Site:'),
            value: guestData?.SiteName,
            icon: <ExploreIcon color="primary" className="guest-request-field-icon" />,
        },
        {
            name: t('Location:'),
            value: guestData?.LocationName,
            icon: <LocationCityIcon color="primary" className="guest-request-field-icon" />,
        },
        {
            name: t('Sub-location:'),
            value: guestData?.SubLocationName,
            icon: <MeetingRoomIcon color="primary" className="guest-request-field-icon" />,
        },
        true
            ? {
                  name: t('Asset:'),
                  value: guestData?.AssetName,
                  icon: <BuildIcon color="primary" className="guest-request-field-icon" />,
              }
            : null,
        true
            ? {
                  name: t('Job Type:'),
                  value: guestData?.JobTypeName,
                  icon: <CategoryIcon color="primary" className="guest-request-field-icon" />,
              }
            : null,
        true
            ? {
                  name: t('Sub Type:'),
                  value: guestData?.JobSubTypeName,
                  icon: <AdjustIcon color="primary" className="guest-request-field-icon" />,
              }
            : null,
    ].filter(notNull => notNull);

    return (
        <div className="guest-request-container">
            <AppBar position="static">
                <Toolbar className="guest-request-toolbar">
                    <Typography variant="h6" align="center" className="guest-request-toolbar-title">
                        {t('Raise a request')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="guest-request-content"
            >
                {guestRequestFields.map((field, index) =>
                    field?.value != null ? (
                        <Grid
                            key={index}
                            container
                            wrap="nowrap"
                            justifyContent="center"
                            alignItems="flex-start"
                            className="guest-request-field"
                        >
                            {field?.icon}
                            <Typography className="guest-request-field-name">{field?.name}</Typography>
                            <Typography className="guest-request-field-value">
                                {field?.value}
                            </Typography>
                        </Grid>
                    ) : null,
                )}
                <form onSubmit={handleSubmit}>
                    {clientSettings?.AssetEnabled && guestData?.AssetFBID == undefined ? (
                        <>
                            <Grid item className="async-select-container-guest">
                                <AsyncAssetDropdown
                                    handleSelectedAssetChange={handleSelectedAssetChange}
                                    clientFBID={guestData?.ClientFBID || ''}
                                    siteID={selectedSite?.SiteFBID || ''}
                                    locationID={selectedLocation?.ID || ''}
                                    sublocationID={selectedSublocation?.ID || ''}
                                    selectedAsset={selectedAsset}
                                />
                            </Grid>
                        </>
                    ) : null}
                    {guestData?.SiteFBID == undefined ? (
                        <>
                            <Grid item className="async-select-container-guest">
                                <AsyncPaginateDropdown
                                    handleSelectedSiteChange={handleSelectedSiteChange}
                                    selectedSite={selectedSite}
                                    clientFBID={guestData?.ClientFBID || ''}
                                />
                            </Grid>
                        </>
                    ) : null}
                    {guestData?.LocationFBID == undefined ? (
                        <>
                            <Grid item className="async-select-container-guest">
                                <AsyncLocationDropdown
                                    handleSelectedLocationChange={handleSelectedLocationChange}
                                    selectedSiteID={selectedSite?.SiteID || ''}
                                    selectedLocation={selectedLocation}
                                    clientFBID={guestData?.ClientFBID || ''}
                                />
                            </Grid>
                        </>
                    ) : null}
                    {guestData?.SubLocationFBID == undefined ? (
                        <>
                            <Grid item className="async-select-container-guest">
                                <AsyncSublocationDropdown
                                    handleSelectedSublocationChange={handleSelectedSublocationChange}
                                    selectedLocationID={selectedLocation?.LocationID || ''}
                                    selectedSublocation={selectedSublocation}
                                    clientFBID={guestData?.ClientFBID || ''}
                                />
                            </Grid>
                        </>
                    ) : null}
                    {clientSettings?.JobTypeEnabled && guestData?.JobTypeFBID == undefined ? (
                        <>
                            <Grid item className="async-select-container-guest">
                                <div className="async-select-container-type">
                                    <AsyncSelect
                                        isRequired={clientSettings?.JobTypeRequired ? true : false}
                                        label={t('Job type')}
                                        nameProperty="JobTypeName"
                                        selectedOption={selectedJobType}
                                        handleSelectedOptionChange={handleSelectedJobTypeChange}
                                        loadFunction={getJobTypesGuest}
                                        dependsOnID={guestData?.ClientFBID}
                                    />
                                </div>
                            </Grid>
                        </>
                    ) : null}
                    {clientSettings?.JobTypeEnabled && guestData?.JobSubTypeFBID == undefined ? (
                        <>
                            <Grid item className="async-select-container-guest">
                                <div className="async-select-container-type">
                                    <AsyncSelect
                                        label={t('Job sub-type')}
                                        nameProperty="JobSubTypeName"
                                        dependsOnMultipleIDs={{
                                            SelectedJobTypeID: selectedJobType?.ID || null,
                                            ClientFBID: guestData?.ClientFBID,
                                        }}
                                        disabledOnID={selectedJobType || null}
                                        selectedOption={selectedJobSubType}
                                        handleSelectedOptionChange={handleSelectedJobSubTypeChange}
                                        loadFunction={getJobSubTypesGuest}
                                    />
                                </div>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item className="guest-request-details">
                        <div className="async-select-container-type">
                            <TextField
                                multiline
                                rows={3}
                                variant="outlined"
                                required
                                className="guest-request-textfields"
                                label={t('Details')}
                                autoFocus
                                value={details}
                                onChange={handleDetailsChange}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="async-select-container-type">
                            <TextField
                                variant="outlined"
                                required
                                className="guest-request-textfields"
                                label={t('Raised By')}
                                value={raisedBy}
                                onChange={handleRaisedByChange}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="async-select-container-type">
                            <TextField
                                type="email"
                                variant="outlined"
                                required
                                className="guest-request-textfields"
                                label={t('Email')}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <input
                            id="image-upload"
                            type="file"
                            multiple
                            onChange={e => saveImage(e.target.files)}
                            style={{ display: 'none' }}
                        />
                    </Grid>
                    <RequestPhotos photos={requestPhotos} getPhotos={getPhotos} guid={guid} />
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            className="add-photo-button"
                            onClick={() => openImageDialog()}
                        >
                            <Icon>add_circle</Icon>&nbsp; {t('Add Photo')}
                        </Button>
                    </Grid>
                    {clientSettings?.GuestRecaptchaEnabled && (
                        <Grid item>
                            <Box width="100%" my={2} display="flex" justifyContent="center">
                                <Reaptcha sitekey={publicKeyRecaptchaV2} ref={setAsRequiredRecaptchaContainer} />
                            </Box>
                        </Grid>
                    )}
                    {clientSettings?.GuestRequestTerms && (
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox required />}
                                label={parse(clientSettings?.GuestRequestTerms)}
                            />
                        </Grid>
                    )}
                    <Button
                        type="submit"
                        className="submit-guest-request-button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? <CircularProgress size={24} color="secondary" /> : t('Raise request')}
                    </Button>
                </form>
            </Grid>
        </div>
    );
};

export default GuestRequest;
