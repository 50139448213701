import {useEffect, useState} from "react";
import AssetClass from "../../definitions/AssetClass";
import Location from "../../definitions/Location";
import SiteType from "../../definitions/SiteType";
import Region from "../../definitions/Region";
import Contract from "../../definitions/Contract";
import { isNullOrUndefined } from "util";

const useSearchFilter = (searchFunction : any, clearFilter : any, site : Site | null, location : Location | null, sublocation : Sublocation | null,
     assetClass : AssetClass | null, subClass : AssetClass | null, region : Region | null, siteType : SiteType | null, contract : Contract | null, textInput: string | null) => {
    const [selectedSite,
        setSelectedSite] = useState < Site | null > (site);
    const [isOpen,
        setIsOpen] = useState(false);
    const [filtersActive,
        setFiltersActive] = useState(false);
    const handleSelectedSiteChange = (newSelectedSite : any) => {
        setSelectedSite(newSelectedSite);
        handleSelectedLocationChange(null);
    };
    const [selectedAssetClass,
        setSelectedAssetClass] = useState < AssetClass | null > (null);
    const [selectedSubClass,
        setSelectedSubClass] = useState < AssetClass | null > (null);
    const [selectedLocation,
        setSelectedLocation] = useState < Location | null > (null);
    const [selectedSublocation,
        setSelectedSublocation] = useState < Sublocation | null > (null);
    const [selectedSiteType,
        setSelectedSiteType] = useState < SiteType | null > (null);
    const [selectedRegion,
        setSelectedRegion] = useState < Region | null > (null);
    const [selectedContract,
        setSelectedContract] = useState < Contract | null > (null);
    const [textSearch,
        setTextSearch] = useState <String | null>("");

    const handleSelectedLocationChange = (newSelectedLocation : any) => {
        setSelectedLocation(newSelectedLocation);
        handleSelectedSublocationChange(null);
    };

    const handleSelectedSublocationChange = (newSelectedSublocation : any) => {
        setSelectedSublocation(newSelectedSublocation);
    };

    const handleSelectedAssetClassChange = (newSelectedAssetClass : any) => {
        setSelectedAssetClass(newSelectedAssetClass);
    };

    const handleSelectedSubClassChange = (newSelectedSubClass : any) => {
        setSelectedSubClass(newSelectedSubClass);
    };

    useEffect(() => {
        if (site != null){
            setSelectedSite(site);
        }
            
        
        if (location != null) 
            setSelectedLocation(location);
        
        if (sublocation != null) 
            setSelectedSublocation(sublocation);
        
        if (assetClass != null) 
            setSelectedAssetClass(assetClass);
        
        if (subClass != null) 
            setSelectedSubClass(subClass);
        
        if (region != null) 
            setSelectedRegion(region);
        
        if (siteType != null) 
            setSelectedSiteType(siteType);
        
        if (contract != null) 
            setSelectedContract(contract);

        console.log("This use effect ran.");
        console.log(textSearch);

        if (textSearch !== undefined && textSearch !== null && textSearch == "") {
            setTextSearch(textSearch);
            console.log("This line of code ran.");
        }
            
        checkFilters();
        }, []
    );

    useEffect(() => {
        setTextSearch(textInput);
        }, []
    );

    const handleSearch = () => {
        console.log("handleSearch() textSearch is: " + textSearch);
        setFiltersActive(true);
        searchFunction({
            SiteID: selectedSite
                ?.ID,
            LocationID: selectedLocation
                ?.ID,
            SubLocationID: selectedSublocation
                ?.ID,
            AssetClassID: selectedAssetClass
                ?.ID,
            SubClassID: selectedSubClass
                ?.ID,
            AssetClass: selectedAssetClass,
            SubClass: selectedSubClass,
            Site: selectedSite,
            Region: selectedRegion,
            RegionID: selectedRegion
                ?.ID,
            Contract: selectedContract,
            ContractID: selectedContract
                ?.ID,
            SiteType: selectedSiteType,
            SiteTypeID: selectedSiteType
                ?.ID,
            TextSearch: textSearch
        });
    };

    const clearFilters = () => {
        clearFilter();
        setFiltersActive(false);
        setSelectedSite(null);
        setSelectedLocation(null);
        setSelectedSublocation(null);
        setTextSearch(null);
        searchFunction({SiteID: null, LocationID: null, SubLocationID: null, clearFilters: true});
    };

    const checkFilters = () => {
        if (selectedSite != null || selectedLocation != null || selectedSublocation != null || assetClass != null || subClass != null
             || selectedRegion != null || selectedSiteType != null || selectedContract != null
              || (textSearch !== null && textSearch !== undefined && textSearch != "")){
                setFiltersActive(true);
              }
        else 
            setFiltersActive(false);
        };

    return {
        isOpen,
        setIsOpen,
        selectedSite,
        setSelectedSite,
        handleSelectedSiteChange,
        selectedLocation,
        setSelectedLocation,
        handleSelectedLocationChange,
        selectedSublocation,
        setSelectedSublocation,
        handleSelectedSublocationChange,
        handleSearch,
        clearFilters,
        filtersActive,
        selectedAssetClass,
        selectedSubClass,
        handleSelectedAssetClassChange,
        handleSelectedSubClassChange,
        selectedSiteType,
        selectedRegion,
        selectedContract,
        setSelectedSiteType,
        setSelectedRegion,
        setSelectedContract,
        setTextSearch,
        textSearch
    };
};

export default useSearchFilter;
