import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#526370"
        },
        secondary: {
            main: "#f59e38"
        },
    }
});

export {theme};
