// dexie import for IndexedDB
import Dexie from 'dexie';

export default class TrackplanDatabase extends Dexie {
	requestphotos!: Dexie.Table<any, number>; // id is number in this case
	createrequestPhotos!: Dexie.Table<any, number>; 
	addRequestPhotos!: Dexie.Table<any, number>; 

	constructor() {
		super('TrackplanRequestDB');
		this.version(1).stores({
			requestphotos: '++id,documentId,&guid',
			createrequestPhotos: '++id,guid',
			addRequestPhotos: '++id,guid',
		});
	
		this.requestphotos = this.table('requestphotos');
		this.createrequestPhotos = this.table('createrequestPhotos')
		this.addRequestPhotos = this.table('addRequestPhotos');;
	}
}
