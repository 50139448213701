import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  LocationCity,
  MeetingRoom,
  NewReleases,
} from "@material-ui/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Location from "../../definitions/Location";
import "../../styles/locations/location-card.css";

type LocationCardProps = {
  isCollapseOpen: boolean;
  location: Location;
};

const LocationCard = ({ isCollapseOpen, location }: LocationCardProps) => {
  const history = useHistory<{ site: Site; location: Location }>();
  const { site } = history.location.state || {};
  const { t, i18n } = useTranslation();

  const handleRaiseRequestForLocation = () => {
    history.push("/new-request", { site, location });
  };

  const handleViewSublocations = () => {
    history.push(`/sublocations/${location.LocationID}`, { site, location });
  };

  useEffect(() => {
    if (!site) {
      history.push("/sites");
    }
  }, [site, history]);

  return (
    <List className="location-card">
      <ListItem>
        <ListItemIcon>
          <LocationCity />
        </ListItemIcon>
        <ListItemText
          primary={location.LocationName}
          className="location-name"
        />
        {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
        <Button
          color="secondary"
          fullWidth
          variant="outlined"
          endIcon={<MeetingRoom />}
          className="view-sublocations-button"
          onClick={handleViewSublocations}
        >
          {t("View Sub-locations")}
        </Button>
        <Button
          fullWidth
          variant="outlined"
          endIcon={<NewReleases />}
          className="raise-request-for-location-button"
          onClick={handleRaiseRequestForLocation}
        >
          {t("Raise request for location")}
        </Button>
      </Collapse>
    </List>
  );
};

export default LocationCard;
