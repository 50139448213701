import { isNullOrUndefined } from 'util';

export default class Algolia {
    algoliasearch: any;
    client: any;
    indexAssets: any;
    indexSites: any;

	constructor() {
        this.algoliasearch = require('algoliasearch');
        this.client = this.algoliasearch('RRKLZGHDHA', '663199fb06b3b13f2069ae08bdf89d6a');
        this.indexAssets = this.client.initIndex('Assets');
        this.indexSites = this.client.initIndex('Sites');
    }

	async assetsSearch(searchInput: string, ClientID: string | null | undefined, userFilters?: any) {
        let assetsResult: any = [];
        let filters = await this.setFiltersAssets(ClientID, userFilters);

        console.log(userFilters);
        console.log(filters);

        await this.indexAssets.setSettings({
            //attributesForFaceting
        }).then(async (data: any) => {
             await this.indexAssets.search(searchInput, {
                filters, hitsPerPage: 100
            }).then((data: any) => {
                assetsResult = data.hits;
                });
        });

        console.log(assetsResult);

        return assetsResult;  
    };

    async sitesSearch(searchInput: string, ClientID: string | null | undefined, userFilters?: any) {
        let sitesResult: any = [];
        let filters = await this.setFiltersSites(ClientID, userFilters);

        console.log(userFilters);

        await this.indexSites.setSettings({
        }).then(async (data: any) => {
             await this.indexSites.search(searchInput, {
                filters, hitsPerPage: 100
            }).then((data: any) => {
                console.log(filters);
                sitesResult = data.hits;
                });
        });

        return sitesResult;  
    };

    async prepareFiltersAssets(userFilters?: any){
        let attributesForFaceting = ['filterOnly(ClientFBID)'] as any;

        if(!isNullOrUndefined(userFilters['site'])){
            attributesForFaceting.push('filterOnly(SiteID)');
        };

        if(!isNullOrUndefined(userFilters['location'])){
            attributesForFaceting.push('filterOnly(LocationID)');
        };

        if(!isNullOrUndefined(userFilters['subLocation'])){
            attributesForFaceting.push('filterOnly(SubLocationID)');
        };

        if(!isNullOrUndefined(userFilters['assetClass'])){
            attributesForFaceting.push('filterOnly(AssetClassFBID)');
        };

        if(!isNullOrUndefined(userFilters['assetSubClass'])){
            attributesForFaceting.push('filterOnly(AssetSubClassFBID)');
        };
        
        return attributesForFaceting; 
    };


    async setFiltersAssets(ClientID: string | null | undefined, userFilters?: any){
        let filters = `ClientFBID:"${ClientID}"`;

        if(!isNullOrUndefined(userFilters['Site'])){
            filters = filters.concat(` AND SiteID:"${userFilters['Site']}"`);
        };

        if(!isNullOrUndefined(userFilters['Location'])){
            filters = filters.concat(` AND LocationID:"${userFilters['Location']}"`);
        };

        if(!isNullOrUndefined(userFilters['SubLocation'])){
            filters = filters.concat(` AND SubLocationID:"${userFilters['SubLocation']}"`);
        };

        if(!isNullOrUndefined(userFilters['AssetClass'])){
            filters = filters.concat(` AND AssetClassFBID:"${userFilters['AssetClass']}"`);
        };

        if(!isNullOrUndefined(userFilters['AssetSubClass'])){
            filters = filters.concat(` AND AssetSubClassFBID:"${userFilters['AssetSubClass']}"`);
        };
        
        return filters; 
    };

    async setFiltersSites(ClientID: string | null | undefined, userFilters?: any){
        let filters = `ClientFBID:"${ClientID}"`;

        console.log(userFilters);

        if(!isNullOrUndefined(userFilters['SiteTypeFBID'])){
            console.log("siteType block of code entered");
            filters = filters.concat(` AND SiteTypeFBID:"${userFilters['SiteTypeFBID']}"`);
        };

        if(!isNullOrUndefined(userFilters['RegionFBID'])){
            filters = filters.concat(` AND RegionFBID:"${userFilters['RegionFBID']}"`);
        };

        if(!isNullOrUndefined(userFilters['ContractFBID'])){
            filters = filters.concat(` AND ContractFBID:"${userFilters['ContractFBID']}"`);
        };

        if(!isNullOrUndefined(userFilters['CanAccessAllSites']) && userFilters['CanAccessAllSites'] == false){
            filters = filters.concat(` AND UsersPermittedToSite:"${userFilters['UserUID']}"`);
        }

        // if(!isNullOrUndefined(userFilters['contractFBID'] && userFilters['contractFBID'] != "")) {
        //     filters = filters.concat(` AND ContractFBID:"${userFilters['contractFBID']}"`);
        // }
             
        return filters; 
    };
}