import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Location from "../definitions/Location";
import config from '../configuration.json';
import {Console} from "console";
import { User } from "../definitions/User";
import { isNullOrUndefined } from "util";
import { getPhotosForCreateRequest, getPhotosForRequest } from "./IndexDb";
import 'firebase/storage';
import { url } from "inspector";
import { JobQueueRequest } from '../definitions/Request';
import { unixToDateString } from "./Times";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

let currentUserClient = {
    ID: Number.NaN,
    UID: "init_uid",
    ServerName: "Ireland",
    UserUID: ""
};

let database : firebase.firestore.Firestore;
let ref: firebase.storage.Reference;

export const initializeFirebase = () => {
    // let firebaseConfig = {}; if (isDev) {   console.log("Is Dev.");
    // firebaseConfig = {     apiKey: "AIzaSyAB5XdnrWo0P21u7YLteQxEQG4PQtTCqyQ",
    // authDomain: "trackplan-manager-dev.firebaseapp.com",     databaseURL:
    // "https://trackplan-manager-dev.firebaseio.com",     projectId:
    // "trackplan-manager-dev",   }; } else {   console.log("Is Staging.");
    // firebaseConfig = {     apiKey: "AIzaSyC9uV6bpqxHdbEdTrrNSLqCmw8yv3sCRpo",
    // authDomain: "trackplan-manager.firebaseapp.com",     databaseURL:
    // "https://trackplan-manager.firebaseio.com",     projectId:
    // "trackplan-manager",   }; }

    firebase.initializeApp(config);

    database = firebase.firestore();
    database
        .enablePersistence({synchronizeTabs: true})
        .catch((err) => {
            if (err.code === "failed-precondition") {
                console.error("Enable Persistence: failed-precondition. Error: " + err);
            } else if (err.code === "unimplemented") {
                console.error("Enable Persistence: unimplemented");
            }
        });

    const auth = firebase.auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    ref = firebase.storage().ref();
    auth.onAuthStateChanged((user) => {

        if (user) {
            const onResult = (queryResult : firebase.firestore.DocumentData) => {
                if (!queryResult.empty) {
                    currentUserClient.UID = queryResult
                        .docs[0]
                        .data()
                        .ClientUID;
                    currentUserClient.ID = queryResult
                        .docs[0]
                        .data()
                        .ClientId;
                    currentUserClient.UserUID = queryResult
                        .docs[0]
                        .data()
                        .UserUID;
                }
                getBaseQuery()
                    .get()
                    .then((queryResult) => (currentUserClient.ServerName = queryResult.data()
                        ?.ServerName || "Ireland"));
            };
            getUserClientUID(user, onResult);
        }
    });
};

const getBaseQuery = () => database
    .collection("Clients")
    .doc(currentUserClient.UID);

const getGuestQuery = (clientFBID: string | undefined) => database
    .collection("Clients")
    .doc(clientFBID);

export const getClientSettingsFirebase = (clientFBID?: string) => {
    if(clientFBID === undefined){
        return database
        .collection("Clients")
        .doc(currentUserClient.UID)
        .get();
    }

    else {
        return database
        .collection("Clients")
        .doc(clientFBID)
        .get();
    }
};

export const getUserSettings = (userUID: string | undefined) => {
    return database
        .collection("RequestorsToClient")
        .where("UserUID", "==", userUID)
            .limit(1)
            .get()
};

const generateFirebaseId = () => {
    let firebaseID = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 20; i++) {
        firebaseID += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return firebaseID;
};

const postToJobQueue = (data : any) => {
    const jobQueueData = {
        ...data,
        UID: currentUserClient.UserUID,
        ClientUID: currentUserClient.UID,
        ServerName: currentUserClient.ServerName,
        DocumentId: generateFirebaseId(),
        Timestamp: new Date().toUTCString()
    };
    const collection = "RequestQueue".concat(currentUserClient.ServerName);
    const time = new Date()
        .getTime()
        .toString();

    return database
        .collection(collection)
        .doc(time)
        .set(jobQueueData);
};

export const uploadBlobImage = (imageBlob: Blob, userUID: string, clientID: string) => {
    const unixTime = Date.now();
    const fileName = `${unixTime}.jpg`;
    const imgPath = `${clientID}/${userUID}/images/${fileName}`;

    return ref
        .child(imgPath)
        .put(imageBlob)
        .then(firebaseRef => {
            return firebaseRef.ref.getDownloadURL();
        })
        .catch(error => console.error(error));
}

export const uploadImageRequest = async (photoArray: any[], userUID: string, clientID: string) => {
    const array: Array<string | null> = [];

    for (const photo of photoArray) {
        const blob = new Blob([photo.file]);
        const url = await uploadBlobImage(blob, userUID, clientID);
        array.push(url);
      }

    return array;
}

export const addPhotosToRequest = async (guid: string, requestFBID: string): Promise<Array<string | null>> => {
    const photoArray = await getPhotosForRequest(guid);
    if (photoArray.length === 0) {
        return [];
    }
    const array = await uploadImageRequest(photoArray, currentUserClient.UserUID, currentUserClient.UID);
    return array;
};

export const postRequestPhotosToJobQueue = (firebasePhotos: (string | null)[], requestFBID: string, guid: string, photoFBIDs: string[] | null) => {
    console.log(photoFBIDs);
    getRequestDocumentFBIDFromGUID(guid, requestFBID).then(res => {
        postToJobQueue({
            JobAction: 'NewRequestPhoto',
            FirebasePhotos: firebasePhotos,
            RequestFBID: requestFBID,
            ClientFBID: currentUserClient.UID,
            PhotoFBIDs: photoFBIDs
        });
    });

    
}

export const getRequestDocumentFBIDFromGUID = (guid: string, requestFBID: string) => {
    return getBaseQuery()
        .collection('Requests')
        .doc(requestFBID)
        .collection('Documents')
        .where('Guid', '==', guid)
        .get()
};

export const deletePhotoFromRequest = async (photoFBID: any, requestFBID: string) => {

    if(isNullOrUndefined(photoFBID))
        return;
   
        getBaseQuery().collection('Requests').doc(requestFBID).collection('Documents').doc(photoFBID).delete();

        return postToJobQueue({
            JobAction: "DeleteRequestPhoto", PhotoFBID: photoFBID, ClientFBID: currentUserClient.UID, RequestFBID: requestFBID
        }); 
    };

export const postToJobQueueAsGuest = async (data: JobQueueRequest): Promise<void> => {
    await getPhotosForCreateRequest(data.Guid).then(async photoArray => {
        if (photoArray.length > 0) {
            // sorry for this "non null assertion", I had no choices
            uploadImageRequest(photoArray, data.userUID!, String(data.ClientID)).then(array => {
                data.FirebasePhotos = array;
                postToGuestRequestQueue(data);
                return;
            });
        } else {
            postToGuestRequestQueue(data);
            return;
        }
    });
};

export const getUserClientUID = (user : firebase.User, onResult : (queryResult : firebase.firestore.DocumentData) => void) => {
    database
        .collection("RequestorsToClient")
        .where("UserUID", "==", user?.uid)
        .limit(1)
        .get()
        .then((queryResult) => {
            onResult(queryResult);
        });
};

export const getRequestorGuest = (guestID : string) => {
    return database
        .collection("RequestorGuests")
        .doc(guestID)
        .get();
};

export const getQRLookupGuest= (guestID : string) => {
    return database
        .collection("QRLookup")
        .doc(guestID)
        .get();
};

export const getSiteForAsset = (siteID : string | undefined) => {
    return getBaseQuery()
        .collection("Sites")
        .doc(siteID)
        .get();
};

export const getLocationForAsset = (locationID : string | undefined) => {
    return getBaseQuery()
        .collection("Locations")
        .doc(locationID)
        .get();
};

export const getSubLocationForAsset = (sublocationID : string | undefined) => {
    return getBaseQuery()
        .collection("SubLocations")
        .doc(sublocationID)
        .get();
};


export const getRequests = (numberOfItems : number, siteID : string | null, locationID : string | null, sublocationID : string | null, canViewAllRequests : boolean | undefined, userID : string | undefined) => {
    var query = getBaseQuery()
        .collection("Requests")
        .orderBy("RequestID", "desc");

    if (sublocationID != null && sublocationID != "") 
        query = query.where("SubLocationFBID", "==", siteID);
    
    if (locationID != null && locationID != "") 
        query = query.where("LocationFBID", "==", locationID);
    
    if (siteID != null && siteID != "") 
        query = query.where("SiteFBID", "==", siteID);
    
    if (!canViewAllRequests) {
        query = query.where('UsersPermittedToRequest', 'array-contains', userID);
    }

    return query
        .limit(numberOfItems)
        .get();
};

export const createNewRequest = async (Details : string, RaisedBy : string | undefined, Email : string | undefined, Guid : string, ClientFBID : string | undefined, Name : string | undefined | null, selectedAsset : Asset, selectedJobType : JobType, selectedJobSubType : JobSubType, selectedSite : Site, selectedLocation : Location, selectedSublocation : Sublocation, JobAction : string, AssetID : number | undefined | null, JobTypeID : number | undefined | null, JobSubTypeID : number | undefined | null, SiteID : number | undefined | null, LocationID : number | undefined | null, SubLocationID : number | undefined | null) => {
    AssetID = (AssetID === undefined) ? null : AssetID;
    JobTypeID = (JobTypeID === undefined) ? null : JobTypeID;
    JobSubTypeID = (JobSubTypeID === undefined) ? null : JobSubTypeID;

    await getPhotosForCreateRequest(Guid)
    .then(async photoArray => {
        if (photoArray.length > 0) {
            uploadImageRequest(photoArray, currentUserClient.UserUID, ClientFBID || "").then(array => {
                    return postToJobQueue({
                        Details, ClientFBID, Name, selectedAsset, selectedJobType,selectedJobSubType, selectedSite, selectedLocation, selectedSublocation,
                        JobAction, AssetID,JobTypeID, JobSubTypeID, SiteID, LocationID, SubLocationID, FirebasePhotos: array, RaisedBy: RaisedBy, Email: Email
                    }); 
            });     
        }
        
    else   
        return postToJobQueue({
            Details, RaisedBy, Email, ClientFBID, Name, selectedAsset, selectedJobType,selectedJobSubType, selectedSite, selectedLocation, selectedSublocation,
            JobAction, AssetID,JobTypeID, JobSubTypeID, SiteID, LocationID, SubLocationID
        }); 
    })
};

export const getAssets = (numberOfItems : number, siteID : string | null, locationID : string | null, sublocationID : string | null, assetClassID : string | null, subClassID : string | null, textSearchActive : boolean) => {
    if (textSearchActive) {
        return null;
    } else {
        var query = getBaseQuery()
            .collection("Assets")
            .orderBy("AssetName", "asc")

        if (sublocationID != null && sublocationID != "") 
            query = query.where("SubLocationFBID", "==", siteID);
        
        if (locationID != null && locationID != "") 
            query = query.where("LocationFBID", "==", locationID);
        
        if (siteID != null && siteID != "") 
            query = query.where("SiteFBID", "==", siteID);
        
        if (assetClassID != null && assetClassID != "") 
            query = query.where("AssetClassFBID", "==", assetClassID);
        
        if (subClassID != null && subClassID != "") 
            query = query.where("AssetSubClassFBID", "==", subClassID);
        
        return query
            .limit(numberOfItems)
            .get();
    }
};

export const getSites = (numberOfItems : number, siteTypeID : string | null, regionID : string | null, contractID : string | null, textSearchActive : boolean, canAccessAllSites : boolean | undefined, userID : string | undefined) => {
    if (textSearchActive) {
        return null;
    } else {
        var query = getBaseQuery()
            .collection("Sites")
            .orderBy("SiteName", "asc")

        if (siteTypeID != null && siteTypeID != "") 
            query = query.where("SiteTypeFBID", "==", siteTypeID);
        
        if (regionID != null && regionID != "") 
            query = query.where("RegionFBID", "==", regionID);
        
        if (contractID != null && contractID != "") 
            query = query.where("ContractFBID", "==", contractID);
        
        if (!canAccessAllSites) {
            query = query.where('UsersPermittedToSite', 'array-contains', userID);
        }

        return query
            .limit(numberOfItems)
            .get();
    }

};

export const getAssetClasses = (user: User | null) => {
    return getBaseQuery()
        .collection("AssetClass")
        .orderBy("AssetClassName", "asc")
        .get();
};

export const getSubClasses = (AssetClassFBID : number, user: User | null) => {
    return getBaseQuery()
        .collection("AssetClass")
        .where("AssetClassFBID", "==", AssetClassFBID)
        .orderBy("AssetClassName", "asc")
        .get();
};

export const getSiteTypes = (user: User | null) => {
    return getBaseQuery()
        .collection("SiteTypes")
        .orderBy("SiteTypeName", "asc")
        .get();
};

export const getRegions = (user: User | null) => {
    return getBaseQuery()
        .collection("Regions")
        .orderBy("RegionName", "asc")
        .get();
};

export const getContracts = (user: User | null) => {
    return getBaseQuery()
        .collection("Contracts")
        .orderBy("ContractName", "asc")
        .get();
};

export const getLocationsOfSite = (siteID : string, numberOfItems : number) => {
    return getBaseQuery()
        .collection("Locations")
        .where("SiteFBID", "==", siteID)
        .limit(numberOfItems)
        .orderBy("LocationName", "asc")
        .get();
};

export const getLocationsOfSiteDropdown = (siteID : string, user: User | null) => {
    return getBaseQuery()
        .collection("Locations")
        .where("SiteFBID", "==", siteID)
        .orderBy("LocationName", "asc")
        .get();
};

export const checkLocationsForSite = (siteID : string | undefined) => {
    return getBaseQuery()
        .collection("Locations")
        .where("SiteFBID", "==", siteID)
        .limit(1)
        .orderBy("LocationName", "asc")
        .get();
};

export const getSublocationsOfLocation = (locationID : string, numberOfItems : number) => {
    return getBaseQuery()
        .collection("SubLocations")
        .where("LocationID", "==", Number(locationID))
        .limit(numberOfItems)
        .orderBy("SubLocationName", "asc")
        .get();
};

export const getSublocationsOfLocationDropdown = (locationID : string, user: User | null) => {
    return getBaseQuery()
        .collection("SubLocations")
        .where("LocationID", "==", Number(locationID))
        .orderBy("SubLocationName", "asc")
        .get();
};

export const getJobTypes = (clientFBID: string, user: User | null) => {
    return getBaseQuery()
        .collection("JobTypes")
        .where("IsPlanned", '==', false)
        .orderBy("JobTypeName", "asc")
        .get();
};

export const getJobTypeGroupById = (groupId:string) =>{
    return getBaseQuery()
        .collection('JobTypeGroups')
        .where(firebase.firestore.FieldPath.documentId(), '==', `${groupId}`)			
        .get();
}


export const getJobSubTypes = (jobTypeID : string, user: UserSettings | null) => {
    return getBaseQuery()
        .collection("JobSubTypes")
        .where("JobTypeFBID", "==", jobTypeID)
        .orderBy("JobSubTypeName", "asc")
        .get();
};

export const getAssetsForDropdowns = (filters: any | null, user: User | null) => {
    var query = getBaseQuery()
                .collection("Assets")
                .orderBy("AssetName", "asc")


    if (filters?.siteID != null) 
        query = query.where("SiteFBID", "==", filters?.siteID);

    if (filters?.locationID != null) 
        query = query.where("LocationFBID", "==", filters?.locationID);

    if (filters?.sublocationID != null) 
        query = query.where("SubLocationFBID", "==", filters?.sublocationID);

    return query.get();
};

export const getSitesDropdown = (user: UserSettings | null ) => {
    var query = getBaseQuery()
        .collection("Sites")
        .orderBy("SiteName", "asc")
 
    if (!user?.CanAccessAllSites) 
        query = query.where('UsersPermittedToSite', 'array-contains', user?.uid);

    return query
        .get();
};

export const getJobSubTypesGuest = (filters: any, user: User | null) => {
    return getGuestQuery(filters.ClientFBID)
        .collection("JobSubTypes")
        .where("JobTypeFBID", "==", filters.SelectedJobTypeID)
        .orderBy("JobSubTypeName", "asc")
        .get();
};

export const getLocationsOfSiteGuest= (filters: any,  user: User | null) => {
    return getGuestQuery(filters.ClientFBID)
        .collection("Locations")
        .where("SiteFBID", "==", filters.SiteID)
        .orderBy("LocationName", "asc")
        .get();
};

export const getSublocationsOfLocationGuest = (filters: any, user: User | null) => {
    return getGuestQuery(filters.ClientFBID)
        .collection("SubLocations")
        .where("LocationID", "==", filters.LocationID)
        .orderBy("SubLocationName", "asc")
        .get();
};


export const getJobTypesGuest = (clientFBID: string, user: User | null) => {
        return getGuestQuery(clientFBID)
        .collection("JobTypes")
        .where("IsPlanned", '==', false)
        .orderBy("JobTypeName", "asc")
        .get();
};

export const getSiteGuest = (siteID : string | undefined, clientFBID: string) => {
    return getGuestQuery(clientFBID)
        .collection("Sites")
        .doc(siteID)
        .get();
};

export const getLocationGuest = (locationID : string | undefined, clientFBID: string) => {
    return getGuestQuery(clientFBID)
        .collection("Locations")
        .doc(locationID)
        .get();
};

export const getSublocationGuest = (sublocationID : string | undefined, clientFBID: string) => {
    return getGuestQuery(clientFBID)
        .collection("SubLocations")
        .doc(sublocationID)
        .get();
};

export const getAssetGuest = (assetID : string | undefined, clientFBID: string) => {
    return getGuestQuery(clientFBID)
        .collection("Assets")
        .doc(assetID)
        .get();
};

export const getJobTypeGuest = (jobTypeFBID : string | undefined, clientFBID: string) => {
    return getGuestQuery(clientFBID)
        .collection("JobTypes")
        .doc(jobTypeFBID)
        .get();
};

export const getAssetsForDropdownsGuest = (filters: any | null, user: User | null) => {

    var query = getGuestQuery(filters.ClientFBID)
                .collection("Assets")
                .orderBy("AssetName", "asc")


    if (filters?.siteID != null) 
        query = query.where("SiteFBID", "==", filters?.siteID);

    if (filters?.locationID != null) 
        query = query.where("LocationFBID", "==", filters?.locationID);

    if (filters?.sublocationID != null) 
        query = query.where("SubLocationFBID", "==", filters?.sublocationID);

    return query.get();
};

export const getSitesDropdownGuest = (filters: any | null, user: User | null ) => {
    return getGuestQuery(filters.ClientFBID)
        .collection("Sites")
        .orderBy("SiteName", "asc")
        .get();
};

export const getSiteForAssetGuest = (siteID : string | undefined, clientFBID: string | undefined) => {
    return getGuestQuery(clientFBID)
        .collection("Sites")
        .doc(siteID)
        .get();
};

export const getLocationForAssetGuest = (locationID : string | undefined, clientFBID: string | undefined) => {
    return getGuestQuery(clientFBID)
        .collection("Locations")
        .doc(locationID)
        .get();
};

export const getSubLocationForAssetGuest = (sublocationID : string | undefined, clientFBID: string | undefined) => {
    return getGuestQuery(clientFBID)
        .collection("SubLocations")
        .doc(sublocationID)
        .get();
};

export const listenUserSettings = (userUID: string | undefined) => {
    return database
        .collection("RequestorsToClient")
        .where("UserUID", "==", userUID)
        .limit(1);
};

export const listenVersionNumber = () => {
    return database
        .collection("RequestVersion")
        .doc("RequestVersion");
};

export const postToGuestRequestQueue = async (data: JobQueueRequest): Promise<void> => {
    const jobQueueData = {
        ...data,
        DocumentId: generateFirebaseId(),
        Timestamp: new Date().toUTCString(),
        UID: 'GuestRequestUser',
    };

    const collection = 'RequestQueue' + data.ServerName;
    const time = new Date().getTime().toString();

    return database
        .collection(collection)
        .doc(time)
        .set(jobQueueData);
};

export const getDocumentQuery = (Collection: string, DocumentId: string) => {
    return getBaseQuery()
        .collection(Collection)
        .doc(DocumentId);
};

export const doesUserExist = async (uid: string): Promise<boolean> => {
    return database
        .collection('RequestorsToClient')
        .where('UserUID', '==', uid)
        .limit(1)
        .get()
        .then(userRecord => userRecord.docs.length > 0);
};

export const hasClientExpired = async (clientID: string): Promise<boolean> => {
    try {
        return database
            .collection('Clients')
            .where('FBID', '==', clientID)
            .limit(1)
            .get()
            .then(client => {
                
                var clientDetails = client.docs[0].data();
                
                if(clientDetails.AccountExpiryDate === null || clientDetails.AccountExpiryDate === undefined){
                    console.log("no client expiry date found")
                    //we decided to let pass users without an expired date set
                    return false;
                }

                //this date needs to be in ms
                var expiryDate = clientDetails.AccountExpiryDate;
                var currentDate = Date.now();

                var objExpiryDate = new Date(expiryDate);
				var objCurrentDate = new Date(currentDate);

				return objExpiryDate < objCurrentDate;
            });
    } catch (error) {
        console.log(error)
		return false;
    }
    
}


export const checkRequestForPhotos = (requestFBID: string) => {
    return database
        .collection('Requests')
        .doc(requestFBID)
        .collection('Documents')
        .get()
};

export const getLanguages = (user: User | null) => {
    return database
        .collection("Languages")
        .orderBy("Location", "asc")
        .get();
};

export const updateUserLanguage = (userUID: string | undefined, newLanguage: string) => {
    return database
        .collection("RequestorsToClient")
        .where("UserUID", "==", userUID)
        .limit(1)
        .get().then(userRecord => {
            var userDocument = userRecord.docs[0].id;
            database.collection("RequestorsToClient").doc(userDocument).update({"Language": newLanguage});
        })
};

export const getUserLanguage = (userUID: string | undefined) => {
    return database
        .collection("RequestorsToClient")
        .where("UserUID", "==", userUID)
        .limit(1)
        .get();
};

export const getLanguage = (languageLabel: string | undefined) => {
    return database
        .collection("Languages")
        .where("LanguageLabel", "==", languageLabel)
        .limit(1)
        .get();
};

export const getSearchSitesPaginated = (items: number, input?: string, lastSite?: any, canAccessAllSites?: boolean, clientFBID?: string) => {
    if(!isNullOrUndefined(clientFBID) && clientFBID != "")
        var query = getGuestQuery(clientFBID).collection("Sites").orderBy("SiteName")
    else
        var query = getBaseQuery().collection('Sites').orderBy('SiteName');

    if(!isNullOrUndefined(canAccessAllSites) && !canAccessAllSites){
        query = query.where('UsersPermittedToSite', 'array-contains', currentUserClient.UserUID);
    }

    if(!isNullOrUndefined(input) && input !== '') {
        query = query.where('SiteNameArray', 'array-contains', input.toLowerCase());
    }

    if(lastSite) query = query.startAfter(lastSite);
    query = query.limit(items);
    return query;
}

export const getSearchLocationsPaginated = (items: number, siteID: string | null | undefined, input?: string, lastLocation?: any, clientFBID?: string) => {
    if(!isNullOrUndefined(clientFBID) && clientFBID != "")
        var query = getGuestQuery(clientFBID).collection("Locations").orderBy("LocationName")
    else
        var query = getBaseQuery().collection('Locations').orderBy('LocationName');

    if(siteID){
        query = query.where('SiteID', '==', parseInt(siteID));
     }
        
    if(!isNullOrUndefined(input) && input !== '') {
        query = query.where('LocationNameArray', 'array-contains', input.toLowerCase());
    }

    if(lastLocation) query = query.startAfter(lastLocation);
    query = query.limit(items);
    return query;
}

export const getSearchSubLocationsPaginated = (items: number, locationID: string | null | undefined, input?: string, lastSubLocation?: any, clientFBID?: string) => {
    if(!isNullOrUndefined(clientFBID) && clientFBID != "")
        var query = getGuestQuery(clientFBID).collection("SubLocations").orderBy("SubLocationName")
    else
        var query = getBaseQuery().collection('SubLocations').orderBy('SubLocationName');

    if (locationID)
        query = query.where('LocationID', '==', parseInt(locationID));

    if(!isNullOrUndefined(input) && input !== '') {
        query = query.where('SubLocationNameArray', 'array-contains', input.toLowerCase());
    }

    if(lastSubLocation) query = query.startAfter(lastSubLocation);
    query = query.limit(items);
    return query;
}

export const getSearchAssetsPaginated = (items: number, siteID?: string, locationID?: string, sublocationID?: string, input?: string, lastAsset?: any, clientFBID?: string) => {
    if(!isNullOrUndefined(clientFBID) && clientFBID != ""){
        var query = getGuestQuery(clientFBID)
            .collection("Assets")
            .orderBy("AssetName", "asc")
    }
    else {
        var query = getBaseQuery()
                .collection("Assets")
                .orderBy("AssetName", "asc")
    }
    

    if (siteID != null && siteID != "") 
        query = query.where("SiteFBID", "==", siteID);

    if (locationID != null && locationID != "") 
        query = query.where("LocationFBID", "==", locationID);

    if (sublocationID != null && sublocationID != "") 
        query = query.where("SubLocationFBID", "==", sublocationID);

    if(!isNullOrUndefined(input) && input !== ''){
        query = query.where('AssetNameArray', 'array-contains', input.toLowerCase());
    }
    
    if(lastAsset) query = query.startAfter(lastAsset);

    query = query.limit(items);
    return query;
};



