import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../auth/useAuth";
import HomeIcon from "@material-ui/icons/Home";
import ExploreIcon from "@material-ui/icons/Explore";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import { getUserSettings, listenUserSettings } from "../../utils/firebase";
import { useTranslation } from "react-i18next";

import logo from "../../assets/images/logo.png";
import dimoLogo from "../../assets/images/dimo-logo.png";

const useLayout = (pathname: string) => {
  const {user} = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userSettings,
    setUserSettings] = useState < UserSettings | null > (null);
  const { t, i18n } = useTranslation();
  const [loginImage, setLoginImage] = useState(logo);


  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    getLogo();
    const unsubscribe = listenUserSettings(user?.uid).onSnapshot(snap => {
        const usersettings = snap.docs[0].data() as UserSettings
        setUserSettings(usersettings)
    });
 
    return () => unsubscribe()
 }, []);

 const getLogo = async() => {
    var url = window.location.href;
    if(url.includes("appdem.trackplanfm.com"))
      setLoginImage(dimoLogo);
};

  const isHomepage = pathname === "/";
  const history = useHistory();
  const handleNavigateTo = (path: string | null | undefined) => {
    if(path != null && path != undefined)
      history.push(path);
    closeDrawer();
  };
  const handleNavigateBack = () => {
    history.goBack();
  };

  const drawerItems = [
  { title: t("Homepage"), url: "/", icon: <HomeIcon /> },
  userSettings?.CanViewRequests ? { title: t("Requests"), url: "/requests", icon: <NewReleasesIcon /> } : null,
  userSettings?.CanViewAssets ? { title: t("Assets"), url: "/assets", icon: <ImportantDevicesIcon /> } : null,
  userSettings?.CanViewSites ? { title: t("Sites"), url: "/sites", icon: <ExploreIcon /> } : null
].filter(notNull => notNull);

  return {
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    isHomepage,
    handleNavigateTo,
    handleNavigateBack,
    user,
    drawerItems,
    loginImage
  };
};

export default useLayout;
