import firebase from "firebase/app";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/auth/useAuth";
import { getJobTypeGroupById } from "../../utils/firebase";
import { IJobTypeGroup } from "../../definitions/JobTypeGroup";

const useAsyncSelect = (
    label: string,
    handleSelectedOptionChange: (newOption: any) => void,
    loadFunction: (...args: any[]) =>
        Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>,
    dependsOnID?: string | null,
    dependsOnMultipleIDs?: object | null,
    userSettings?: UserSettings
) => {

    const [isLoading,
        setIsLoading] = useState(false);
    const [isOpen,
        setIsOpen] = useState(false);
    const [options,
        setOptions] = useState<any[]>([]);
    const { user } = useAuth();


    const onSuccess = async (result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
        const options = result
            .docs
            .map((doc) => {
                const option = doc.data();
                option.ID = doc.id;
                return option as any;
            });

        const userJobTypeGroupId = userSettings && userSettings.JobTypeGroupFbId;
        if (label === "Job type" && userJobTypeGroupId) {
            let userJobTypeGroup: IJobTypeGroup;
            const jobGroupData = await getJobTypeGroupById(userJobTypeGroupId);
            if (!jobGroupData.empty) {
                userJobTypeGroup = { ...jobGroupData.docs[0].data(), Id: jobGroupData.docs[0].id };
                const userJobIds = userJobTypeGroup.JobTypeArray || [];
                const userJobTypeOptions = options
                    .filter(_opt => userJobIds.some(item => item === _opt.ID));
                setOptions(userJobTypeOptions);
            } else {
                setOptions([]);
            }
        } else {
            setOptions(options);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (dependsOnID !== null || label == 'Asset') {
            setIsLoading(true);
            if (dependsOnID === undefined && dependsOnMultipleIDs === undefined) {
                loadFunction(user).then(onSuccess);
            } else {
                if (dependsOnID === undefined)
                    loadFunction(dependsOnMultipleIDs, user).then(onSuccess);
                else
                    loadFunction(dependsOnID, user).then(onSuccess);
            }
        }
    }, [dependsOnID, dependsOnMultipleIDs, loadFunction]);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOptionChange = (_: any, value: any) => {
        handleSelectedOptionChange(value);
    };

    return {
        options,
        isLoading,
        isOpen,
        handleOpen,
        handleClose,
        handleOptionChange
    };
};

export default useAsyncSelect;
