import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { FormEvent } from "react";
import logo from "../../assets/images/logo.png";
import useChangePassword from "../../hooks/settings/useChangePassword";
import "../../styles/settings/change-password.css";
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const {
    currentPassword,
    handleCurrentPasswordChange,
    newPassword,
    handleNewPasswordChange,
    newPasswordConfirmation,
    handleNewPasswordConfirmationChange,
    changePassword,
    isChangingPassword,
    changePasswordError,
    changePasswordSuccess,
  } = useChangePassword();
  const { t, i18n } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changePassword();
  };

  return (
    <div className="change-password-main-container">
      <div className="change-password-form-container">
        <img src={logo} alt="Trackplan logo" className="logo" />
        <Typography variant="h1" color="primary" className="enter-new-password">
        {t("Change Password")}
        </Typography>
        <form onSubmit={handleSubmit} className="change-password-form">
          <TextField
            required
            className="change-password-textfield"
            id="current-password"
            type="password"
            label={t("Current Password")}
            margin="normal"
            disabled={isChangingPassword}
            value={currentPassword}
            onChange={handleCurrentPasswordChange}
          />
          <TextField
            required
            className="change-password-textfield"
            id="new-password"
            type="password"
            label={t("New Password")}
            margin="normal"
            disabled={isChangingPassword}
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
          <TextField
            required
            className="change-password-textfield"
            id="confirm-new-password"
            type="password"
            label={t("Confirm New Password")}
            margin="normal"
            disabled={isChangingPassword}
            value={newPasswordConfirmation}
            onChange={handleNewPasswordConfirmationChange}
          />
          {changePasswordError && (
            <Typography
              variant="subtitle2"
              color="error"
              align="justify"
              className="change-password-message"
            >
              {changePasswordError}
            </Typography>
          )}
          {changePasswordSuccess && (
            <Typography
              variant="subtitle2"
              color="primary"
              align="justify"
              className="change-password-message"
            >
              {changePasswordSuccess}
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="change-password-form-button"
            disabled={isChangingPassword}
          >
            {isChangingPassword ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              t("Change Password")
            )}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
