import { Button, Card, CardContent, Collapse, Grid, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore, NewReleases } from '@material-ui/icons';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

import { useHistory } from 'react-router-dom';
import { getSiteForAsset } from '../../utils/firebase';

import '../../styles/assets/asset-card.css';
import { useTranslation } from 'react-i18next';
import ReactShowMoreTextRipple from '../shared/ReactShowMoreTextRipple';
import { useRef } from 'react';

type AssetCardProps = {
    asset: Asset;
    isCollapseOpen: boolean;
};

const AssetCard = ({ asset, isCollapseOpen }: AssetCardProps): JSX.Element => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const assetFields = [
        {
            name: t('Class'),
            value: asset.AssetClass,
        },
        {
            name: t('Site'),
            value: asset.SiteName,
        },
        {
            name: t('Location'),
            value: asset.LocationName,
        },
        {
            name: t('Sub-location'),
            value: asset.SubLocationName,
        },
    ];

    const handleRaiseRequestForAsset = () => {
        getSiteForAsset(asset.SiteFBID).then(siteData => {
            var site = null;
            site = siteData.data();
            history.push('/new-request', { asset, site });
        });
    };

    return (
        <Card className="asset-card">
            <CardContent className="asset-card-content">
                <Grid container alignItems="center" justifyContent="center" className="asset-header-container">
                    <ImportantDevicesIcon color="primary" className="asset-header-icon" />
                    <Typography variant="h5" color="primary" align="center">
                        {asset.AssetName}
                    </Typography>
                    {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
                </Grid>
                <Grid container direction="row" className="asset-card-fields-container" spacing={1}>
                    {assetFields.map(assetField => (
                        <AssetCardItem assetField={assetField} isCollapseOpen={isCollapseOpen} key={assetField.name} />
                    ))}
                </Grid>
                <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                    <Button
                        fullWidth
                        variant="text"
                        endIcon={<NewReleases />}
                        className="raise-request-for-site-button"
                        onClick={handleRaiseRequestForAsset}
                    >
                        {t('Raise request for asset')}
                    </Button>
                </Collapse>
            </CardContent>
        </Card>
    );
};

const AssetCardItem = ({
    assetField,
    isCollapseOpen,
}: {
    isCollapseOpen: boolean;
    assetField: {
        name: string;
        value: string | null;
    };
}) => {
    // Close and open the truncated texts on click
    const showMoreRef = useRef<any>(null);
    if (isCollapseOpen && !showMoreRef.current?.state?.expanded) {
        // Untyped react-show-more-text internal to toggleLines. Toggle only if text is truncated or expanded
        showMoreRef.current?.toggleLines?.(document.createEvent('MouseEvent'));
    }

    if (!isCollapseOpen && showMoreRef.current?.state?.expanded) {
        // Untyped react-show-more-text internal to toggleLines. Toggle only if text is truncated or expanded
        showMoreRef.current?.toggleLines?.(document.createEvent('MouseEvent'));
    }
    return (
        <Grid item className="asset-field-container" sm={6} xs={12}>
            <Typography className="asset-field-name">
                {assetField.name}
                :&nbsp;
            </Typography>
            <ReactShowMoreTextRipple
                anchorClass="asset-field--show-more-anchor-class"
                className="MuiTypography-root MuiTypography-body1 MuiTypography-alignLeft"
                stringContent={assetField.value || ""}
                lines={1}
                less={null}
                more={null}
            >
                <Typography align="left">{assetField.value}</Typography>
            </ReactShowMoreTextRipple>
        </Grid>
    );
};

export default AssetCard;
