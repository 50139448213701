import {CircularProgress, Typography} from "@material-ui/core";
import "../../styles/shared/loading-spinner.css";

type LoadingSpinnerProps = {
    text: string;
};

const LoadingSpinner = ({text} : LoadingSpinnerProps) => (
    <div className="loading-spinner-container">
        <Typography variant="h1" color="primary" className="loading-spinner-text">
            {text}
        </Typography>
        <CircularProgress size={50} color="secondary"/>
    </div>
);

export default LoadingSpinner;
