import { Button, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore, Explore, LocationCity, NewReleases } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import '../../styles/sites/site-card.css';
import { useTranslation } from 'react-i18next';

type SiteCardProps = {
    isCollapseOpen: boolean;
    site: Site;
};

const SiteCard = ({ isCollapseOpen, site }: SiteCardProps): JSX.Element => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const handleRaiseRequestForSite = () => {
        history.push('/new-request', { site });
    };

    const handleViewLocations = () => {
        history.push(`/locations/${site.ID}`, { site });
    };

    return (
        <List className="site-card">
            <ListItem>
                <ListItemIcon>
                    <Explore />
                </ListItemIcon>
                <ListItemText primary={site.SiteName} className="site-name" />{' '}
                {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                <Button
                    color="secondary"
                    fullWidth
                    variant="outlined"
                    endIcon={<LocationCity />}
                    className="view-locations-button"
                    onClick={handleViewLocations}
                >
                    {t('View Locations')}
                </Button>
                <Button
                    fullWidth
                    variant="outlined"
                    endIcon={<NewReleases />}
                    className="raise-request-for-site-button"
                    onClick={handleRaiseRequestForSite}
                >
                    {t('Raise request for site')}
                </Button>
            </Collapse>
        </List>
    );
};

export default SiteCard;
