import firebase from "firebase/app";
import {useEffect, useRef, useState} from "react";
import { getSearchLocationsPaginated, getSearchSubLocationsPaginated } from "../../utils/firebase";
import useAuth from "../auth/useAuth";
import { isNullOrUndefined } from "util";

const useSublocationPaginate = (selectedLocationID: string | null | undefined, handleSelectedSublocationChange: any, selectedSublocation: Sublocation | any, clientFBID?: string ) => {
    const [label, setLabel] = useState("");
    const [sublocationValue, setSublocationValue] = useState("");
    const [loadedSublocations, setLoadedSublocations] = useState([]);
    const [lastLoadedSublocation, setLastLoadedSublocation] = useState<any>();
    const [sublocationsPerPage, setSublocationsPerPage] = useState(50);
    const [endSublocation, setEndSublocation] = useState(false);
    const [sublocationFilter, setSublocationFilter] = useState <{value: number; label: string;} | null>();
    const [sublocationMenuOpen, setSublocationMenuOpen] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const sublocationRef = useRef<HTMLInputElement>();
    const firstRender= useRef(true);

    const {user} = useAuth();

    useEffect(() => {
        if(selectedSublocation?.SubLocationID != null)
            setSublocationFilter({value: parseInt(selectedSublocation.SubLocationID), label: selectedSublocation?.SubLocationName || ""});
      }, []);

      useEffect(() => {
        if(selectedSublocation?.SubLocationID != null)
            setSublocationFilter({value: parseInt(selectedSublocation.SubLocationID), label: selectedSublocation?.SubLocationName || ""});
      }, [selectedSublocation]);
      
    useEffect(() => {
        if(firstRender.current) {
            firstRender.current = false;
            return;
        }

        setLastLoadedSublocation(null);
        setSublocationFilter(null);
        setLoadedSublocations([]);
        setSublocationValue("");
      }, [selectedLocationID]);

    const loadSublocations = async(sublocationName: string, loadedOptions: any) => {
        var lastLoadedSublocationCached: any = lastLoadedSublocation;
        var sublocationValueCached: any = sublocationValue;
        if(sublocationName !== sublocationValue) {
            lastLoadedSublocationCached = null;
            sublocationValueCached = sublocationName;
            await setLoadedSublocations([]);
            setLastLoadedSublocation(null);
            await setSublocationValue(sublocationName);
            loadedOptions = [];
        };

        return new Promise((resolve) => {
            getSearchSubLocationsPaginated(sublocationsPerPage, selectedLocationID, sublocationValueCached, lastLoadedSublocationCached, clientFBID).get().then(docs => {
                if(!docs.empty) {
                    let sublocations: any = [];
                    docs.forEach(function(sublocation) {
                        let sublocationValue = sublocation.data();
                        sublocationValue.ID = sublocation.id;
                        
                        const sublocationData = {
                            value: sublocationValue,
                            label: sublocation.data().SubLocationName
                        }
                        sublocations.push(sublocationData);
                    });
                    setLoadedSublocations(sublocations);
                    setLastLoadedSublocation(docs.docs[docs.docs.length - 1])
                    return resolve({
                        options: sublocations,
                        hasMore: !endSublocation
                    });
                } else {
                    setEndSublocation(true);
                    return resolve({
                        options: [],
                        hasMore: false
                    });
                }
            })
        })
	};

    const clearSubLocation = () => {
        setLastLoadedSublocation(null);
        setSublocationFilter(null);
        setLoadedSublocations([]);
        setSublocationValue("");
    };

    const openSublocationMenu = () => {
        setSublocationMenuOpen(true);
        if(!(sublocationRef.current === null || sublocationRef.current === undefined)){
            sublocationRef.current.focus();
        }          
    };

    const closeSublocationMenu = () => setSublocationMenuOpen(false);

    const handleSublocationChange = (sublocation: any) => {
        handleSelectedSublocationChange(sublocation);
        if(sublocation == null){
            clearSubLocation();
            return;     
        }
        setSublocationFilter({value: sublocation.value, label: sublocation.label});
    };
	
    return {
        loadSublocations,
        sublocationFilter,
        sublocationRef,
        openSublocationMenu,
        closeSublocationMenu,
        sublocationMenuOpen,
        handleSublocationChange,
        showKeyboard,
        sublocationValue
    };
};

export default useSublocationPaginate;
