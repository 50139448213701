import { Box, Grid } from '@material-ui/core';
import RequestCard from '../../components/requests/RequestCard';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import NoResults from '../../components/shared/NoResults';
import PaginationButton from '../../components/shared/PaginationButton';
import useRequests from '../../hooks/requests/useRequests';
import SearchFilter from '../../components/shared/SearchFilter';
import { useTranslation } from 'react-i18next';

const Requests = () => {
    const {
        requests,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore,
        searchFunction,
        selectedSite,
        clearFilter,
        selectedLocation,
        selectedSubLocation,
        collapsedRequestID,
        setCollapsedRequestID,
        requestPhotos,
        isPhotosLoading,
        saveImage,
        deleteImage,
    } = useRequests();
    const { t, i18n } = useTranslation();

    if (isLoading) {
        return <LoadingSpinner text={t('Loading Requests')} />;
    }

    if (isError) {
        return <ErrorDisplay errorMessage={(error as any).message} retry={refetch} />;
    }

    if (requests.length === 0) {
        return <NoResults itemName={t('Requests')} />;
    }

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" direction="column">
                {requests.map(request => (
                    <Grid item key={request.ID} style={{ width: '100%' }}>
                        <Box marginTop={1}>
                            <RequestCard
                                request={request}
                                requestPhotos={requestPhotos}
                                isCollapseOpen={request.ID === collapsedRequestID}
                                isPhotosLoading={isPhotosLoading}
                                setCollapsedRequestID={setCollapsedRequestID}
                                saveImage={saveImage}
                                deletePhoto={deleteImage}
                            />
                        </Box>
                    </Grid>
                ))}
                <Grid item>
                    <PaginationButton
                        width="100%"
                        isEndOfList={isEndOfList}
                        itemName={t('Requests')}
                        handleLoadMore={handleLoadMore}
                    />
                </Grid>
            </Grid>
            <SearchFilter
                label={t('Requests Search')}
                searchFunction={searchFunction}
                clearFilter={clearFilter}
                site={selectedSite}
                location={selectedLocation}
                sublocation={selectedSubLocation}
                region={null}
                siteType={null}
                contract={null}
                assetClass={null}
                subClass={null}
                textInput={null}
                displayTextSearch={false}
            />
        </>
    );
};

export default Requests;
