import { ButtonBase } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useRef } from 'react';
import ReactShowMoreText, { ReactShowMoreTextProps } from 'react-show-more-text';

/**
 * ReactShowMoreTextRipple is a wrapper around ReactShowMoreText that adds a ripple effect to the text when it is clicked.
 */
export default function ReactShowMoreTextRipple({
    children,
    ...showMoreProps
}: { children?: React.ReactNode, stringContent: string | null } & ReactShowMoreTextProps): JSX.Element {
    const showMoreRef = useRef<any>(null);
    var lines = 2;
    
    if(showMoreProps && showMoreProps.stringContent)
        lines = showMoreProps.stringContent.length < 20 ? -1 : 2;

    console.log(lines);

    return (
        // Untyped react-show-more-text internal to toggleLines. Toggle only if text is truncated or expanded
        <ButtonBase
            onClick={e =>
                (showMoreRef.current?.state?.truncated || showMoreRef.current?.state?.expanded) &&
                showMoreRef.current?.toggleLines?.(e)
            }
        >
            <ReactShowMoreText
                lines={lines}
                more={<ExpandMore />}
                less={
                    <ExpandLess
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    />
                }
                ref={showMoreRef}
                {...showMoreProps}
            >
                {children}
            </ReactShowMoreText>
        </ButtonBase>
    );
}
