import {
  AppBar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExploreIcon from "@material-ui/icons/Explore";
import HomeIcon from "@material-ui/icons/Home";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import MenuIcon from "@material-ui/icons/Menu";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import SettingsIcon from "@material-ui/icons/Settings";
import { ReactChild } from "react";
import logo from "../../assets/images/logo.png";
import dimoLogo from "../../assets/images/dimo-logo.png";
import useLayout from "../../hooks/layout/useLayout";
import "../../styles/shared/layout.css";
import { useTranslation } from 'react-i18next';

type LayoutProps = {
  pathname: string;
  title: string;
  children: ReactChild | ReactChild[];
};

const Layout = ({ pathname, title, children }: LayoutProps) => {
  const {
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    isHomepage,
    handleNavigateTo,
    handleNavigateBack,
    user,
    drawerItems,
    loginImage
  } = useLayout(pathname);
  const { t, i18n } = useTranslation();
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className="menu-button"
            onClick={openDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="title">
            {title}
          </Typography>
          {!isHomepage && (
            <IconButton color="inherit" onClick={handleNavigateBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <List>
          <ListItem>
            <img src={loginImage} alt="Trackplan logo" className="drawer-logo" />
          </ListItem>
        </List>
        {drawerItems?.map((drawerItem, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleNavigateTo(drawerItem?.url)}
          >
            <ListItemIcon>{drawerItem?.icon}</ListItemIcon>
            <ListItemText primary={drawerItem?.title} />
          </ListItem>
          
        ))}
        <List className="drawer-settings">
          <Divider />
          <ListItem button onClick={() => handleNavigateTo("/settings")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t("Settings")} />
          </ListItem>
        </List>
      </SwipeableDrawer>
      {children}
    </>
  );
};

export default Layout;
