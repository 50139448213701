import firebase from "firebase/app";
import {useEffect, useRef, useState} from "react";
import { getSearchSitesPaginated } from "../../utils/firebase";
import useAuth from "../../hooks/auth/useAuth";
import { isNullOrUndefined } from "util";

const useAsyncPaginate = (handleSelectedSiteChange: any, selectedSite: Site | null, clientFBID?: string) => {
    const [label, setLabel] = useState("");
    const [siteValue, setSiteValue] = useState("");
    const [loadedSites, setLoadedSites] = useState([]);
    const [lastLoadedSite, setLastLoadedSite] = useState<any>();
    const [sitesPerPage, setSitesPerPage] = useState(50);
    const [endSite, setEndSite] = useState(false);
    const [siteFilter, setSiteFilter] = useState <{value: number; label: string;} | null>();
    const [siteMenuOpen, setSiteMenuOpen] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const siteRef = useRef<HTMLInputElement>();

    const {user} = useAuth();

    useEffect(() => {
        if(selectedSite?.SiteID != null)
            setSiteFilter({value: parseInt(selectedSite.SiteID), label: selectedSite?.SiteName || ""});
      }, []);

      useEffect(() => {
        if(selectedSite?.SiteID != null)
            setSiteFilter({value: parseInt(selectedSite.SiteID), label: selectedSite?.SiteName || ""});
      }, [selectedSite]);

    const loadSites = async(siteName: string, loadedOptions: any) => {
        var lastLoadedSiteCached: any = lastLoadedSite;
        var siteValueCached: any = siteValue;

        if(siteName !== siteValue) {
            lastLoadedSiteCached = null;
            siteValueCached = siteName;
            await setLoadedSites([]);
            await setLastLoadedSite(null);
            await setSiteValue(siteName);
            loadedOptions = [];
        }

        return new Promise((resolve) => {
            getSearchSitesPaginated(sitesPerPage, siteValueCached, lastLoadedSiteCached, user?.CanAccessAllSites, clientFBID).get().then(docs => {
                if(!docs.empty) {
                    let sites: any = [];
                    docs.forEach(function(site) {
                        let siteValueData = site.data();
                        siteValueData.ID = site.id;
                        
                        const siteData = {
                            value: siteValueData,
                            label: site.data().SiteName
                        }
                        sites.push(siteData);
                    });
                    setLoadedSites(sites);
                    setLastLoadedSite(docs.docs[docs.docs.length - 1])
                    return resolve({
                        options: sites,
                        hasMore: !endSite
                    });
                } else {
                    setEndSite(true);
                    return resolve({
                        options: [],
                        hasMore: false
                    });
                }
            })
        })
	};

    const openSiteMenu = () => {
        setSiteMenuOpen(true);
        if(!(siteRef.current === null || siteRef.current === undefined)){
            siteRef.current.focus();
        }
            
    };

    const closeSiteMenu = () => setSiteMenuOpen(false);

    const clearSite = () => {
        setLastLoadedSite(null);
        setSiteFilter(null);
        setLoadedSites([]);
        setSiteValue("");
        setEndSite(false);
    };

    const handleSiteChange = (site: any) => {
        handleSelectedSiteChange(site);
        if(site == null){
            clearSite();
            return;     
        }
        setSiteFilter({value: site.value, label: site.label});
        setLastLoadedSite(null);

    };
	
    return {
        loadSites,
        siteFilter,
        siteRef,
        openSiteMenu,
        closeSiteMenu,
        siteMenuOpen,
        handleSiteChange,
        showKeyboard,
        siteValue
    };
};

export default useAsyncPaginate;
