import { useTranslation } from "react-i18next";
import useAsyncPaginate from "../../hooks/async-paginate/useLocationPaginate";
import { AsyncPaginate } from 'react-select-async-paginate';
import '../../styles/new-request/new-request.css';
import useLocationPaginate from "../../hooks/async-paginate/useLocationPaginate";
import useSublocationPaginate from "../../hooks/async-paginate/UseSublocationPaginate";
import { Grid, Icon, IconButton, InputLabel } from "@material-ui/core";

type AsyncSublocationProps = {
  handleSelectedSublocationChange: (newOption: any) => void;
  selectedLocationID: string | null | undefined;
  selectedSublocation: Sublocation | null;
  clientFBID?: string;
};

const AsyncSublocationDropdown = ({handleSelectedSublocationChange, selectedLocationID, selectedSublocation, clientFBID}: AsyncSublocationProps) => {
  const {
    loadSublocations,
    sublocationFilter,
    sublocationRef,
    openSublocationMenu,
    closeSublocationMenu,
    sublocationMenuOpen,
    handleSublocationChange,
    showKeyboard,
    sublocationValue,
  } = useSublocationPaginate(selectedLocationID, handleSelectedSublocationChange, selectedSublocation, clientFBID);
  const { t } = useTranslation();

  return (
    <>   
    <InputLabel style={{ marginBottom: 7, display: 'flex' }}>{t("Sublocation")}</InputLabel>
    <div className="select-container">
      <div className="select-sub-container">
      <AsyncPaginate
          isRequired={true}
          required={true}
          id="sublocation-helper"
          value={sublocationFilter}
          selectRef={sublocationRef}
          onFocus={openSublocationMenu}
          onBlur={closeSublocationMenu}
          menuIsOpen={sublocationMenuOpen}
          onChange={handleSublocationChange}
          blurInputOnSelect={true}
          loadOptions={loadSublocations}
          loadingMessage={() => t("Loading Sublocations...")}
          noOptionsMessage={() => t("No Sublocations Found")}
          cacheUniqs={[sublocationFilter, sublocationValue, selectedLocationID]}
          isDisabled={!selectedLocationID}
          isSearchable={ true }
        /> 
    </div>
  {sublocationFilter != null ? <div className="clear-button-container">
    <div>
      <IconButton onClick={() => handleSublocationChange(null)} className="clear-button">
        <Icon color="error">delete_forever</Icon>
      </IconButton>
    </div>
  </div> : null
}
  
</div>
</>
  );
};

export default AsyncSublocationDropdown;
