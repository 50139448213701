import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import AddCommentIcon from "@material-ui/icons/AddComment";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "../../styles/homepage/homepage.css";
import { useTranslation } from 'react-i18next';
import useVersionCheck from "../../hooks/auth/useVersionCheck";
import { useEffect, useState } from "react";
import dimoLogo from "../../assets/images/dimo-logo.png";

const Homepage = () => {
  const { t, i18n } = useTranslation();
  const [loginImage, setLoginImage] = useState(logo);
  
  useEffect(() => {
    getLogo();
 }, []);

  const getLogo = async() => {
    var url = window.location.href;
    if(url.includes("appdem.trackplanfm.com"))
      setLoginImage(dimoLogo);
};

  return (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    className="homepage-main-container"
    direction="column"
  >
    <Grid item>
      <img src={loginImage} alt="Trackplan logo" className="homepage-logo" />
    </Grid>
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className="homepage-buttons-container"
    >
      <Link to="/new-request" className="homepage-link">
        <Card className="new-request-card">
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <AddCommentIcon className="new-request-icon" />
              <Typography className="homepage-link-text" variant="button">
              {t('Raise new request')}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Link>
      <Link to="/requests" className="homepage-link">
        <Card className="view-requests-card">
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <NewReleasesIcon
                className="view-requests-icon"
                color="secondary"
              />
              <Typography className="homepage-link-text" variant="button">
              {t('View requests')}       
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  </Grid>
  )
};

export default Homepage;
