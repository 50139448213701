import useAuth from "../auth/useAuth";
import {useEffect, useState} from "react";
import i18n from "../../i18n";
import { getLanguage, getUserLanguage, updateUserLanguage } from "../../utils/firebase";

const useSettings = () => {
  const { user, signout } = useAuth();
  const [selectedLanguage, setSelectedLanguage] = useState < any > (null);
  const isPasswordAuthenticated = user?.providerData[0]?.providerId === "password";

  useEffect(() => {
    initializeLanguage();
 }, []);

 const initializeLanguage = async() => {
  getUserLanguage(user?.uid).then(userRecord => {
    console.log("1");
    var userLanguage = userRecord.docs[0].data().Language;
    if(userLanguage != undefined && userLanguage != null){
      console.log("2 is " + userLanguage);
      getLanguage(userLanguage).then(language => {
        console.log("3 is " + JSON.stringify(language.docs[0]));
        if(language.docs[0] != undefined && language.docs[0] != null)
          setSelectedLanguage(language.docs[0].data());
      })
    }
  });
};

  const handleSelectedLanguageChange = (newLanguage: any) => {
    setSelectedLanguage(newLanguage);

    if(newLanguage != null){
      i18n.changeLanguage(newLanguage.ID);
      updateUserLanguage(user?.uid, newLanguage.ID);
    }
      
    else {
      i18n.changeLanguage("English");
      updateUserLanguage(user?.uid, "English"); 
    }
  };

  const handleSignout = () => {
    signout();
  };

  return { user, isPasswordAuthenticated, selectedLanguage, handleSignout, handleSelectedLanguageChange };
};

export default useSettings;