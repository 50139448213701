import firebase from "firebase/app";
import {useEffect, useRef, useState} from "react";
import { getSearchLocationsPaginated } from "../../utils/firebase";
import useAuth from "../../hooks/auth/useAuth";
import Location from "../../definitions/Location";

const useLocationPaginate = (selectedSiteID: string | null | undefined, handleSelectedLocationChange: any, selectedLocation: Location | null, clientFBID?: string ) => {
    const [label, setLabel] = useState("");
    const [locationValue, setLocationValue] = useState("");
    const [loadedLocations, setLoadedLocations] = useState([]);
    const [lastLoadedLocation, setLastLoadedLocation] = useState<any>(null);
    const [locationsPerPage, setLocationsPerPage] = useState(50);
    const [endLocation, setEndLocation] = useState(false);
    const [locationFilter, setLocationFilter] = useState <{value: number; label: string;} | null>();
    const [locationMenuOpen, setLocationMenuOpen] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const locationRef = useRef<HTMLInputElement>();
    const firstRender= useRef(true);

    const {user} = useAuth();


    useEffect(() => {
        if(selectedLocation?.LocationID != null)
            setLocationFilter({value: parseInt(selectedLocation.LocationID), label: selectedLocation?.LocationName || ""});
      }, [selectedLocation]);

      useEffect(() => {
        if(selectedLocation?.LocationID != null)
            setLocationFilter({value: parseInt(selectedLocation.LocationID), label: selectedLocation?.LocationName || ""});
      }, []);

    useEffect(() => {
        if(firstRender.current){
            firstRender.current = false;
            return;
        }

        setLastLoadedLocation(null);
        setLocationFilter(null);
        setLoadedLocations([]);
        setLocationValue("");
      }, [selectedSiteID]);

    const loadLocations = async(locationName: string, loadedOptions: any) => {
        var lastLoadedLocationCached: any = lastLoadedLocation;
        var locationValueCached: any = locationValue;
        if(locationName !== locationValue) {
            lastLoadedLocationCached = null;
            locationValueCached = locationName;
            await setLoadedLocations([]);
            await setLastLoadedLocation(null);
            await setLocationValue(locationName);
            loadedOptions = [];
        }

        return new Promise((resolve) => {
            getSearchLocationsPaginated(locationsPerPage, selectedSiteID, locationValueCached, lastLoadedLocationCached, clientFBID).get().then(async docs => {
                if(!docs.empty) {
                    let locations: any = [];
                    docs.forEach(function(location) {
                        let locationValue = location.data();
                        locationValue.ID = location.id;
                        
                        const locationData = {
                            value: locationValue,
                            label: location.data().LocationName
                        }
                        locations.push(locationData);
                    });
                    await setLoadedLocations(locations);
                    await setLastLoadedLocation(docs.docs[docs.docs.length - 1]);
                    return resolve({
                        options: locations,
                        hasMore: !endLocation
                    });
                } else {
                    setEndLocation(true);
                    return resolve({
                        options: [],
                        hasMore: false
                    });
                }
            })
        })
	};

    const openLocationMenu = () => {
        setLocationMenuOpen(true);
        if(!(locationRef.current === null || locationRef.current === undefined)){
            locationRef.current.focus();
        }
            
    };

    const closeLocationMenu = () => setLocationMenuOpen(false);

    const clearLocation = () => {
        setLastLoadedLocation(null);
        setLocationFilter(null);
        setLoadedLocations([]);
        setLocationValue("");
    };

    const handleLocationChange = (location: any) => {
        handleSelectedLocationChange(location);
        if(location == null){
            //setSublocationFilter({value: 0, label: "Select..."});  
            clearLocation();
            return;     
        }
        setLocationFilter({value: location.value, label: location.label});
        setLastLoadedLocation(null);
    };
	
    return {
        loadLocations,
        locationFilter,
        locationRef,
        openLocationMenu,
        closeLocationMenu,
        locationMenuOpen,
        handleLocationChange,
        showKeyboard,
        locationValue
    };
};

export default useLocationPaginate;
