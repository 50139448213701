import { ReactChild } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import CheckingDetailsSpinner from "./components/shared/CheckingDetailsSpinner";
import Layout from "./components/shared/Layout";
import useAuth from "./hooks/auth/useAuth";
import useVersionCheck from "./hooks/auth/useVersionCheck";
import Assets from "./views/assets/Assets";
import GuestRequest from "./views/guest-request/GuestRequest";
import Homepage from "./views/homepage/Homepage";
import Locations from "./views/locations/Locations";
import ForgottenPassword from "./views/login/ForgottenPassword";
import Login from "./views/login/Login";
import NewRequest from "./views/new-request/NewRequest";
import Requests from "./views/requests/Requests";
import ChangePassword from "./views/settings/ChangePassword";
import Settings from "./views/settings/Settings";
import Sites from "./views/sites/Sites";
import Sublocations from "./views/sublocations/Sublocations";


type PrivateRouteProps = {
  exact?: boolean;
  path: string;
  children: ReactChild | ReactChild[];
};

const PrivateRoute = ({ exact, path, children }: PrivateRouteProps) => {
  const { user, isCheckingDetails } = useAuth();
  //useVersionCheck();
  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => {
        if (isCheckingDetails) {
          return <CheckingDetailsSpinner />;
        }
        return user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const App = () => {
  const { t, i18n } = useTranslation();

  const privateRoutes = [
    { title: t("Requests"), path: "/requests", view: <Requests /> },
    { title: t("New request"), path: "/new-request", view: <NewRequest /> },
    { title: t("Assets"), path: "/assets", view: <Assets /> },
    { title: t("Sites"), path: "/sites", view: <Sites /> },
    { title: t("Locations"), path: "/locations/:siteID", view: <Locations /> },
    {
      title: t("Sub-locations"),
      path: "/sublocations/:locationID",
      view: <Sublocations />,
    },
    { title: t("Settings"), path: "/settings", view: <Settings /> },
    {
      title: t("Change Password"),
      path: "/settings/change-password",
      view: <ChangePassword />,
    },
  ];

  return (
  <Router>
    <Switch>
      <Route path="/login/:redirected?">
        <Login />
      </Route>
      <Route path="/forgotten-password">
        <ForgottenPassword />
      </Route>
      <Route path="/guest-request/:guestID">
        <GuestRequest />
      </Route>
      {privateRoutes.map((privateRoute, index) => (
        <PrivateRoute key={index} path={privateRoute.path} exact>
          <Layout title={privateRoute.title} pathname={privateRoute.path}>
            {privateRoute.view}
          </Layout>
        </PrivateRoute>
      ))}
      <PrivateRoute path="*">
        <Layout title={t("Request App")} pathname="/">
          <Homepage />
        </Layout>
      </PrivateRoute>
    </Switch>
  </Router>
  )
};

export default App;
