import { useSnackbar } from 'notistack';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';
import useAuth from '../../hooks/auth/useAuth';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import {
    checkLocationsForSite,
    getClientSettingsFirebase,
    getRequestorGuest,
    postToJobQueueAsGuest,
    getLocationGuest,
    getJobTypeGuest,
    getSiteGuest,
    getSiteForAssetGuest,
    getLocationForAssetGuest,
    getSubLocationForAssetGuest,
    getQRLookupGuest,
} from '../../utils/firebase';

import { generateGuid, getPhotosForCreateRequest, setPhotoForCreateRequest } from '../../utils/IndexDb';

import loadImage from 'blueimp-load-image';
import { fileToArrayBuffer } from '../../utils/Converters';
import { isNullOrUndefined } from 'util';
import Location from '../../definitions/Location';
import { useTranslation } from 'react-i18next';
import GuestData from '../../definitions/GuestData';

const useGuestRequest = () => {
    const history = useHistory();
    const { user, signinAsGuest, signout } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation();
    const { guestID } = useParams<{
        guestID: string;
    }>();
    const [isLoading, setIsLoading] = useState(true);
    const [guestData, setGuestData] = useState<GuestData | null>(null);
    const [details, setDetails] = useState('');
    const [raisedBy, setRaisedBy] = useState('');
    const [email, setEmail] = useState('');
    const [guid, setGuid] = useState(generateGuid);
    const [clientSettings, setClientSettings] = useState<ClientSettings | null>();
    const [isLocationsForSite, setIsLocationsForSite] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any | null>(null);
    const [selectedSublocation, setSelectedSublocation] = useState<Sublocation | null>(null);
    const [selectedJobType, setSelectedJobType] = useState<any | null>(null);
    const [selectedJobSubType, setSelectedJobSubType] = useState<JobSubType | null>(null);
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [selectedSite, setSelectedSite] = useState<any | null>(null);
    const [recaptchaVerifyToken, setRecaptchaVerifyToken] = useState<string | null>(null);
    const [requestPhotos, setRequestPhotos] = useState<RequestPhoto[]>([]);

    useEffect(() => {
        signinAsGuest().then(() => {
            getRequestorGuest(guestID).then(async requestorGuest => {
                if (requestorGuest.exists) {
                    await setGuestData(requestorGuest.data() as GuestData);

                    if (guestData?.SiteFBID != null && guestData?.SiteFBID != undefined) {
                        await checkLocationsForSite(requestorGuest.data()?.SiteFBID).then(data => {
                            if (data.docs.length > 0) {
                                setIsLocationsForSite(true);
                            } else {
                                setIsLocationsForSite(false);
                            }
                        });
                    }
                    setIsLoading(false);
                } else {
                    getQRLookupGuest(guestID).then(async qrGuest => {
                        if (qrGuest.exists) {
                            await setGuestData(qrGuest.data() as GuestData);
                            if (guestData?.SiteFBID != null && guestData?.SiteFBID != undefined) {
                                await checkLocationsForSite(qrGuest.data()?.SiteFBID).then(data => {
                                    if (data.docs.length > 0) {
                                        setIsLocationsForSite(true);
                                    } else {
                                        setIsLocationsForSite(false);
                                    }
                                });
                            }
                            setIsLoading(false);
                        } else {
                            history.push('/login');
                        }
                    });
                }
            });
        });
    }, [signinAsGuest, guestID, history]);

    useEffect(() => {
        getClientSettingsFirebase(guestData?.ClientFBID).then(result => {
            setClientSettings(result.data() as ClientSettings);
        });
        if (guestData?.Language != undefined) {
            i18n.changeLanguage(guestData?.Language);
        }

        initializeSite();
        initializeLocation();
        initializeJobType();
    }, [isLoading]);

    const handleLogin = () => {
        signout().then(() => history.push('/login'));
    };

    const handleDetailsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDetails(event.target.value);
    };

    const handleRaisedByChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRaisedBy(event.target.value);
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value);
    };

    const handleSelectedSiteChange = (newSelectedSite: any) => {
        if (newSelectedSite?.value) newSelectedSite = newSelectedSite.value;

        if (newSelectedSite) {
            checkLocationsForSite(newSelectedSite?.ID).then(data => {
                if (data.docs.length > 0) {
                    setIsLocationsForSite(true);
                } else {
                    setIsLocationsForSite(false);
                }
            });
        }

        setSelectedSite(newSelectedSite);
        handleSelectedLocationChange(null);
        handleSelectedAssetChange(null);
    };

    const handleSelectedLocationChange = (newSelectedLocation: any) => {
        if (newSelectedLocation?.value) newSelectedLocation = newSelectedLocation.value;

        setSelectedLocation(newSelectedLocation);
        handleSelectedSublocationChange(null);
        setSelectedAsset(null);
    };

    const handleSelectedSublocationChange = (newSelectedSublocation: any) => {
        if (newSelectedSublocation?.value) newSelectedSublocation = newSelectedSublocation.value;

        setSelectedSublocation(newSelectedSublocation);
        setSelectedAsset(null);
    };

    const handleSelectedJobTypeChange = (newSelectedJobType: any) => {
        setSelectedJobType(newSelectedJobType);
        handleSelectedJobSubTypeChange(null);
    };

    const handleSelectedJobSubTypeChange = (newSelectedJobSubType: any) => {
        setSelectedJobSubType(newSelectedJobSubType);
    };

    const handleSelectedAssetChange = (newSelectedAsset: any) => {
        if (newSelectedAsset?.value) newSelectedAsset = newSelectedAsset.value;

        if (newSelectedAsset?.SiteFBID) {
            getSiteForAssetGuest(newSelectedAsset?.SiteFBID, guestData?.ClientFBID).then(siteData => {
                var site = siteData.data() as Site;
                if (site != null && site != undefined) {
                    if (site) {
                        checkLocationsForSite(newSelectedAsset?.SiteFBID).then(data => {
                            if (data.docs.length > 0) {
                                setIsLocationsForSite(true);
                            } else {
                                setIsLocationsForSite(false);
                            }
                        });
                    }
                    setSelectedSite(site);
                    setSelectedLocation(null);
                    setSelectedSublocation(null);
                }
            });
        }

        if (newSelectedAsset?.LocationFBID) {
            getLocationForAssetGuest(newSelectedAsset?.LocationFBID, guestData?.ClientFBID).then(locationData => {
                const location = locationData.data() as Location;
                if (location != null && location != undefined) {
                    setSelectedLocation(location);
                    setSelectedSublocation(null);
                }
            });
        }

        if (newSelectedAsset?.SubLocationFBID) {
            getSubLocationForAssetGuest(newSelectedAsset?.SubLocationFBID, guestData?.ClientFBID).then(
                sublocationData => {
                    const sublocation = sublocationData.data() as Sublocation;
                    if (sublocation != null && sublocation != undefined) setSelectedSublocation(sublocation);
                },
            );
        }
        setSelectedAsset(newSelectedAsset);
    };

    const openImageDialog = () => {
        const imageUploadButton = document.getElementById('image-upload');
        if (imageUploadButton !== null) {
            imageUploadButton.click();
        }
    };

    const { mutate: createRequest, isLoading: isSubmitting, reset: resetMutation } = useMutation(
        () =>
            postToJobQueueAsGuest({
                ClientFBID: guestData?.ClientFBID || null,
                SiteFBID: guestData?.SiteFBID || selectedSite?.SiteFBID,
                LocationFBID: guestData?.LocationFBID || selectedLocation?.LocationFBID || null,
                SubLocationFBID: guestData?.SubLocationFBID || null,
                AssetFBID: guestData?.AssetFBID || selectedAsset?.AssetFBID || null,
                JobTypeFBID: guestData?.JobTypeFBID || null,
                JobSubTypeFBID: guestData?.JobSubTypeFBID || null,
                SiteID: guestData?.SiteID || selectedSite?.SiteID,
                LocationID: guestData?.LocationID || selectedLocation?.LocationID || null,
                SubLocationID: guestData?.SubLocationID || selectedSublocation?.SubLocationID || null,
                JobTypeID: guestData?.JobTypeID || selectedJobType?.JobTypeID || null,
                JobSubTypeID: guestData?.JobSubTypeID || selectedJobSubType?.JobSubTypeID || null,
                AssetID: guestData?.AssetID || selectedAsset?.AssetID || null,
                ClientID: guestData?.ClientID || 0,
                JobAction: 'NewGuestRequest',
                ServerName: clientSettings?.ServerName || null,
                RaisedBy: raisedBy,
                RaisedByEmail: email,
                Details: details,
                Guid: guid,
                FirebasePhotos: [],
                userUID: null,
                RecaptchaVerifyToken: recaptchaVerifyToken,
            }),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Request raised successfully, we will inform you of any updates by Email'), {
                    variant: 'success',
                    onClose: () => closeSnackbar(),
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
                setDetails('');
                setRaisedBy('');
                setEmail('');

                const newGuid: string = generateGuid();
                setGuid(newGuid);
                getPhotos(newGuid);
            },
            onError: (error: { message: string }) => alert(error.message),
        },
    );

    const getPhotos = (guid: string) => {
        if (isNullOrUndefined(guid)) return;

        getPhotosForCreateRequest(guid).then(photoArray => {
            const arrayMap = photoArray.map(photo => {
                const blob = new Blob([photo.file]);
                const blobUrl = URL.createObjectURL(blob);
                const id = photo.id;
                return { FirebaseStoragePath: blobUrl, id: id };
            });
            setRequestPhotos(arrayMap);
        });
    };
    const saveImage = async (selectorFiles: FileList | null) => {
        if (selectorFiles !== null) {
            for (let i = 0; i < selectorFiles.length; i++) {
                const photo: Blob = selectorFiles[i];
                const CompressionRatio = GetFileCompressionRatio(photo);

                loadImage(
                    photo,
                    (canvas: HTMLCanvasElement | HTMLImageElement | any) => {
                        canvas.toBlob(async (blob: Blob) => {
                            const arrayBuffer = await fileToArrayBuffer(blob as Blob);

                            await setPhotoForCreateRequest(arrayBuffer, guid)
                                .then(generatedGuid => {
                                    getPhotos(guid);
                                })
                                .catch(err => console.error(err));
                        });
                    },
                    {
                        maxWidth: 400,
                        maxHeight: 400,
                        orientation: true,
                        downsamplingRatio: CompressionRatio,
                        canvas: true
                    },
                );
            }
        }
    };

    const onVerifyRecaptcha = (token: string) => {
        setRecaptchaVerifyToken(token);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // we do not want to reload the page on submit

        resetMutation();
        createRequest();

        if (!navigator.onLine) {
            resetMutation();
            enqueueSnackbar(
                t(
                    'You appear to be offline. Your request has been stored and will be raised once you reconnect to the internet',
                ),
                {
                    variant: 'info',
                    onClose: () => closeSnackbar(),
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                },
            );
            setDetails('');
            handleSelectedAssetChange(null);
            handleSelectedJobTypeChange(null);
            handleSelectedSiteChange(null);
        }
    };

    const initializeSite = () => {
        if (guestData?.SiteFBID !== undefined && guestData?.SiteFBID !== null) {
            getSiteGuest(guestData?.SiteFBID, guestData?.ClientFBID).then(siteData => {
                const site = siteData.data() as Site;
                if (site != null && site != undefined) {
                    setSelectedSite(site);
                }
            });
        }
    };

    const initializeLocation = () => {
        if (guestData?.LocationFBID !== undefined && guestData?.LocationFBID !== null) {
            getLocationGuest(guestData?.LocationFBID, guestData?.ClientFBID).then(locationData => {
                const location = locationData.data() as Location;
                if (location != null && location != undefined) {
                    setSelectedLocation(location);
                }
            });
        }
    };

    const initializeJobType = () => {
        if (guestData?.JobTypeFBID !== undefined && guestData?.JobTypeFBID !== null) {
            getJobTypeGuest(guestData?.JobTypeFBID, guestData?.ClientFBID).then(jobTypeData => {
                const jobType = jobTypeData.data() as JobType;
                jobType.ID = jobTypeData.id;
                if (jobType != null && jobType != undefined) {
                    setSelectedJobType(jobType);
                }
            });
        }
    };

    return {
        isLoading,
        isSubmitting,
        guestData,
        details,
        raisedBy,
        email,
        clientSettings,
        isLocationsForSite,
        selectedLocation,
        selectedSublocation,
        selectedJobType,
        selectedJobSubType,
        selectedAsset,
        selectedSite,
        requestPhotos,
        handleLogin,
        handleSubmit,
        handleDetailsChange,
        handleRaisedByChange,
        handleEmailChange,
        openImageDialog,
        setSelectedLocation,
        onVerifyRecaptcha,
        handleSelectedLocationChange,
        handleSelectedSublocationChange,
        handleSelectedJobTypeChange,
        handleSelectedJobSubTypeChange,
        handleSelectedAssetChange,
        handleSelectedSiteChange,
        saveImage,
        getPhotos,
        guid,
    };
};

export default useGuestRequest;
