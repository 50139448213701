import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { createContext, ReactChild } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { AuthContext } from './definitions/AuthContext';
import useProvideAuth from './hooks/auth/useProvideAuth';
import './';
import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { theme } from './styles/theme';
import { initializeFirebase } from './utils/firebase';
import { initializeIndexDB } from './utils/IndexDb';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n';

Sentry.init({
    dsn: 'https://5cf55b266b564b569d439aa2ea9b102e@o343443.ingest.sentry.io/6058489',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

initializeFirebase();
initializeIndexDB();

export const authContext = createContext({} as AuthContext);

const AuthProvider = ({ children }: { children: ReactChild | ReactChild[] }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const queryClient = new QueryClient();

ReactDOM.render(
    <AuthProvider>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <CssBaseline />
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </QueryClientProvider>
    </AuthProvider>,
    document.getElementById('root'),
);

//serviceWorker.register();
serviceWorkerRegistration.register();
