export const getColorFromStatus = (status: string) => {
  switch (status) {
    case "Completed":
    case "Submitted":
    case "Approved":
    case "Authorised":
    case "Complete":
      return "#2E7D32";

    case "User Confirmed Complete":
    case "Contractor Confirmed Complete":
    case "Resource Confirmed Complete":
    case "Form Submitted":
      return "#00b309";

    case "Pending":
      return "#f59e38";

    case "Instructed":
      return "#FFC107";

    case "More Work Required":
    case "Re-Opened":
    case "Form Not Submitted":
      return "#db8200";

    case "Accepted":
    case "Accepted Task":
    case "Work Confirmed":
      return "#66BB6A";

    case "Arrived":
    case "Started":
      return "#FFC107";

    case "Started Travel":
    case "Travel Start":
      return "#2196F3";

    case "Left Site":
      return "#F57F17";

    case "Left Task":
      return "#F57F17";

    case "Cancelled":
    case "UnderQuery":
    case "Declined":
    case "Work Rejected":
      return "#c62828";

    case "On Hold":
    case "Rejected By User":
    case "Requires Auth.":
    case "Requires Authorisation":
      return "#d55858";

    default:
      return "transparent";
  }
};

export const getIconFromStatus = (status: string) => {
  switch (status) {
    case "Completed":
    case "Submitted":
    case "Approved":
    case "Authorised":
    case "Complete":
      return "done_all";

    case "User Confirmed Complete":
    case "Contractor Confirmed Complete":
    case "Resource Confirmed Complete":
    case "Form Submitted":
      return "done";

    case "Pending":
      return "hourglass_empty";

    case "More Work Required":
    case "Re-Opened":
    case "Form Not Submitted":
      return "work";

    case "Accepted":
    case "Accepted Task":
    case "Work Confirmed":
      return "check_circle";

    case "Started Travel":
    case "Travel Start":
    case "Left Site":
    case "Left Task":
      return "local_shipping";

    case "Cancelled":
    case "UnderQuery":
    case "Declined":
    case "Work Rejected":
      return "cancel";

    case "On Hold":
    case "Rejected By User":
    case "Requires Auth.":
    case "Requires Authorisation":
      return "block";

    case "Instructed":
      return "build";

    case "Arrived":
    case "Started":
      return "domain";

    default:
      return null;
  }
};
