import Fab from '@material-ui/core/Fab/Fab';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { DialogContent, Dialog, Grid, DialogActions, Button, DialogTitle, TextField } from '@material-ui/core';

import '../../styles/search/search.css';
import useSearchFilter from '../../hooks/search-filter/useSearchFilter';
import AsyncSelect from './AsyncSelect';
import Location from '../../definitions/Location';

import {
    getLocationsOfSiteDropdown,
    getSublocationsOfLocationDropdown,
    getAssetClasses,
    getSubClasses,
    getRegions,
    getSiteTypes,
    getContracts,
    getSitesDropdown,
} from '../../utils/firebase';
import { Icon, IconButton, InputLabel } from '@material-ui/core';

import '../../styles/new-request/new-request.css';
import AssetClass from '../../definitions/AssetClass';
import Region from '../../definitions/Region';
import SiteType from '../../definitions/SiteType';
import Contract from '../../definitions/Contract';
import { useTranslation } from 'react-i18next';

type SearchFilterProps = {
    label: string;
    searchFunction: (dropdownValues: any) => void;
    site: Site | null;
    location: Location | null;
    sublocation: Sublocation | null;
    assetClass: AssetClass | null;
    subClass: AssetClass | null;
    region: Region | null;
    siteType: SiteType | null;
    contract: Contract | null;
    textInput: string | null;
    displayTextSearch: boolean;
    clearFilter: () => void;
};

const SearchFilter = ({
    label,
    searchFunction,
    clearFilter,
    site,
    location,
    sublocation,
    assetClass,
    subClass,
    region,
    siteType,
    contract,
    textInput,
    displayTextSearch,
}: SearchFilterProps) => {
    const {
        isOpen,
        setIsOpen,
        selectedSite,
        handleSelectedSiteChange,
        selectedLocation,
        handleSelectedLocationChange,
        selectedSublocation,
        handleSelectedSublocationChange,
        handleSearch,
        clearFilters,
        filtersActive,
        selectedAssetClass,
        selectedSubClass,
        handleSelectedAssetClassChange,
        handleSelectedSubClassChange,
        selectedSiteType,
        selectedRegion,
        selectedContract,
        setSelectedSiteType,
        setSelectedRegion,
        setSelectedContract,
        setTextSearch,
        textSearch,
    } = useSearchFilter(
        searchFunction,
        clearFilter,
        site,
        location,
        sublocation,
        assetClass,
        subClass,
        region,
        siteType,
        contract,
        textInput,
    );
    const { t } = useTranslation();

    return (
        <div>
            <Fab color="primary" aria-label="add" id="search-fab" onClick={() => setIsOpen(true)}>
                <SearchIcon />
            </Fab>
            <Fab
                className={filtersActive ? '' : 'hidden'}
                color="primary"
                aria-label="add"
                id="search-fab"
                style={{
                    marginBottom: '50px',
                    backgroundColor: '#fb1919',
                }}
                onClick={() => clearFilters()}
            >
                <ClearIcon />
            </Fab>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby={label} fullWidth maxWidth="sm">
                <DialogTitle>{label}</DialogTitle>
                <DialogContent id="content">
                    <Grid
                        container
                        direction="column"
                        justify-content="flex-start"
                        spacing={3}
                        style={{
                            marginTop: 1,
                        }}
                    >
                        {displayTextSearch ? (
                            <Grid item xs={12}>
                                <InputLabel htmlFor="free-text-input">{t('Search')}</InputLabel>
                                <TextField
                                    margin="dense"
                                    id="free-text-input"
                                    type="text"
                                    value={textSearch}
                                    onChange={e => setTextSearch(e.target.value)}
                                    fullWidth={true}
                                />
                            </Grid>
                        ) : null}
                        {label != 'Site Search' ? (
                            <>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        isRequired
                                        label={t('Site')}
                                        nameProperty="SiteName"
                                        selectedOption={selectedSite}
                                        handleSelectedOptionChange={handleSelectedSiteChange}
                                        loadFunction={getSitesDropdown}
                                    />
                                </Grid>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Location')}
                                        nameProperty="LocationName"
                                        dependsOnID={selectedSite?.ID || null}
                                        disabledOnID={selectedSite?.ID || null}
                                        selectedOption={selectedLocation}
                                        handleSelectedOptionChange={handleSelectedLocationChange}
                                        loadFunction={getLocationsOfSiteDropdown}
                                    />
                                </Grid>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Sub-location')}
                                        nameProperty="SubLocationName"
                                        dependsOnID={selectedLocation?.LocationID || null}
                                        disabledOnID={selectedLocation?.LocationID || null}
                                        selectedOption={selectedSublocation}
                                        handleSelectedOptionChange={handleSelectedSublocationChange}
                                        loadFunction={getSublocationsOfLocationDropdown}
                                    />
                                </Grid>
                            </>
                        ) : null}

                        {label == 'Assets Search' ? (
                            <>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Asset Class')}
                                        nameProperty="AssetClassName"
                                        selectedOption={selectedAssetClass}
                                        handleSelectedOptionChange={handleSelectedAssetClassChange}
                                        loadFunction={getAssetClasses}
                                    />
                                </Grid>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Asset Sub Class')}
                                        nameProperty="AssetSubClassName"
                                        dependsOnID={selectedAssetClass?.ID || null}
                                        disabledOnID={selectedAssetClass?.ID}
                                        selectedOption={selectedSubClass}
                                        handleSelectedOptionChange={handleSelectedSubClassChange}
                                        loadFunction={getSubClasses}
                                    />
                                </Grid>
                            </>
                        ) : null}
                        {label == 'Site Search' ? (
                            <>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Site Type')}
                                        nameProperty="SiteTypeName"
                                        selectedOption={selectedSiteType}
                                        handleSelectedOptionChange={setSelectedSiteType}
                                        loadFunction={getSiteTypes}
                                    />
                                </Grid>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Region')}
                                        nameProperty="RegionName"
                                        selectedOption={selectedRegion}
                                        handleSelectedOptionChange={setSelectedRegion}
                                        loadFunction={getRegions}
                                    />
                                </Grid>
                                <Grid item className="async-select-container">
                                    <AsyncSelect
                                        label={t('Contract')}
                                        nameProperty="ContractName"
                                        selectedOption={selectedContract}
                                        handleSelectedOptionChange={setSelectedContract}
                                        loadFunction={getContracts}
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: 'dialog-action-buttons',
                    }}
                >
                    <Button onClick={() => setIsOpen(false)} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={() => handleSearch()} color="primary">
                        {t('Search')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SearchFilter;
