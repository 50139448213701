import loadImage from "blueimp-load-image";
import {useSnackbar} from "notistack";
import {ChangeEvent, FormEvent, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useMutation} from "react-query";
import {useHistory} from "react-router-dom";
import { isNullOrUndefined } from "util";
import Location from "../../definitions/Location";
import { fileToArrayBuffer } from "../../utils/Converters";
import { GetFileCompressionRatio } from "../../utils/FileCompressionRatio";
import {createNewRequest, getClientSettingsFirebase, checkLocationsForSite, getSiteForAsset, getLocationForAsset, getSubLocationForAsset, listenUserSettings} from "../../utils/firebase";
import { generateGuid, getPhotosForCreateRequest, setPhotoForCreateRequest } from "../../utils/IndexDb";
import useAuth from "../auth/useAuth";

const useNewRequest = () => {
    const { user, isCheckingDetails } = useAuth();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const { t, i18n } = useTranslation();
    const history = useHistory < {
        site: Site;
        location: Location;
        sublocation: Sublocation;
        asset: Asset;
    } > ();
    const {
        site = null,
        location = null,
        sublocation = null,
        asset = null
    } = history.location.state || {};
    const [isLocationsForSite,
        setIsLocationsForSite] = useState(true);
    const [details,
        setDetails] = useState("");
    const [raisedBy,
        setRaisedBy] = useState <string | undefined> ("");
    const [email,
        setEmail] = useState <string | undefined> ("");
    const [selectedAsset,
        setSelectedAsset] = useState < Asset | null > (asset);
    const [selectedJobType,
        setSelectedJobType] = useState < JobType | null > (null);
    const [selectedJobSubType,
        setSelectedJobSubType] = useState < JobSubType | null > (null);
    const [selectedSite,
        setSelectedSite] = useState < Site | null > (site);
    const [selectedLocation,
        setSelectedLocation] = useState < Location | null > (location);
    const [selectedSublocation,
        setSelectedSublocation] = useState < Sublocation | null > (sublocation);
    const [userSettings,
        setUserSettings] = useState < UserSettings> ({});
    const [requestPhotos,
        setRequestPhotos] = useState < RequestPhoto[] > ([]);
    const [guid,
        setGuid] = useState(generateGuid);

    
    const [clientSettings,
        setClientSettings] = useState < ClientSettings | null > ();
    // const [selectedPriority,
    //     setSelectedPriority] = useState < SelectedPriority | null > ();

    useEffect(() => { 
        getClientSettingsFirebase().then((result) => {
            setClientSettings(result.data() as ClientSettings);
        });
        setEmail(user?.email);
        setRaisedBy(user?.Name);
    }, []);

    useEffect(() => {
        const unsubscribe = listenUserSettings(user?.uid).onSnapshot(snap => {
            const usersettings = snap.docs[0].data() as UserSettings
            setUserSettings(usersettings)
        });
     
        return () => unsubscribe()
     }, []);

    const handleDetailsChange = (event : ChangeEvent < HTMLInputElement | HTMLTextAreaElement >) => {
        setDetails(event.target.value);
    };

    const handleSelectedAssetChange = (newSelectedAsset : any) => {
        console.log(newSelectedAsset + "newAsset");
        if(newSelectedAsset?.value)
            newSelectedAsset = newSelectedAsset.value;

        if(newSelectedAsset?.SiteFBID) {
            getSiteForAsset(newSelectedAsset?.SiteFBID).then((siteData) => {
                var site = siteData.data() as Site;
                    if(site != null && site != undefined){
                        if(site){
                            checkLocationsForSite(newSelectedAsset?.SiteFBID).then(data => {
                                if (data.docs.length > 0) {
                                    setIsLocationsForSite(true);
                                } else {
                                    setIsLocationsForSite(false);
                                }
                            });
                        }                       
                        setSelectedSite(site);
                        setSelectedLocation(null);
                        setSelectedSublocation(null);
                    }
        });
    };

        console.log(newSelectedAsset + "newAsset");
        if(newSelectedAsset?.LocationFBID) {
            console.log("565");
            getLocationForAsset(newSelectedAsset?.LocationFBID).then((locationData) => {
                var location = locationData.data() as Location;
                    if(location != null && location != undefined){
                        setSelectedLocation(location);
                        setSelectedSublocation(null);
                    };                
        });
    };

        if(newSelectedAsset?.SubLocationFBID) {
            getSubLocationForAsset(newSelectedAsset?.SubLocationFBID).then((sublocationData) => {
                var sublocation = sublocationData.data() as Sublocation;
                    if(sublocation != null && sublocation != undefined)
                    setSelectedSublocation(sublocation);
        });
        };

        setSelectedAsset(newSelectedAsset);
    };

    const handleRaisedByChange = (event : ChangeEvent < HTMLInputElement | HTMLTextAreaElement >) => {
        setRaisedBy(event.target.value);
    };

    const handleEmailChange = (event : ChangeEvent < HTMLInputElement | HTMLTextAreaElement >) => {
        setEmail(event.target.value);
    };

    const handleSelectedJobTypeChange = (newSelectedJobType : any) => {
        setSelectedJobType(newSelectedJobType);
        handleSelectedJobSubTypeChange(null);
    };

    const handleSelectedJobSubTypeChange = (newSelectedJobSubType : any) => {
        setSelectedJobSubType(newSelectedJobSubType);
    };

    const handleSelectedSiteChange = (newSelectedSite : any) => {
        console.log(newSelectedSite);
        if(newSelectedSite?.value) 
            newSelectedSite = newSelectedSite.value;

        if(newSelectedSite){
            checkLocationsForSite(newSelectedSite?.ID).then(data => {
                if (data.docs.length > 0) {
                    setIsLocationsForSite(true);
                } else {
                    setIsLocationsForSite(false);
                }
            });
        }
        
        setSelectedSite(newSelectedSite);
        handleSelectedLocationChange(null);
        handleSelectedAssetChange(null);
    };

    const handleSelectedSiteChangeLazy = (newSelectedSite : any) => {
        if(newSelectedSite.value)
            newSelectedSite = newSelectedSite.value;

        if(newSelectedSite){
            checkLocationsForSite(newSelectedSite?.ID).then(data => {
                if (data.docs.length > 0) {
                    setIsLocationsForSite(true);
                } else {
                    setIsLocationsForSite(false);
                }
            });
        }
        
        setSelectedSite(newSelectedSite);
        handleSelectedLocationChange(null);
        handleSelectedAssetChange(null);
    };

    const handleSelectedLocationChange = (newSelectedLocation : any) => {
        console.log(newSelectedLocation + "55");
        if(newSelectedLocation?.value)
            newSelectedLocation = newSelectedLocation.value;

        setSelectedLocation(newSelectedLocation);
        handleSelectedSublocationChange(null);
        //setSelectedAsset(null);
    };

    

    const handleSelectedSublocationChange = (newSelectedSublocation : any) => {
        if(newSelectedSublocation?.value)
            newSelectedSublocation = newSelectedSublocation.value;
        setSelectedSublocation(newSelectedSublocation);
        //setSelectedAsset(null);
    };

    const {mutate: createRequest, isLoading, status, isIdle, error, reset: resetMutation} = useMutation(() => createNewRequest(details, raisedBy, email, guid, user?.ClientUID, user?.displayName, selectedAsset as Asset, selectedJobType as JobType, selectedJobSubType as JobSubType, selectedSite as Site, selectedLocation as Location, selectedSublocation as Sublocation, "NewRequest", selectedAsset
        ?.AssetID, selectedJobType?.JobTypeID, selectedJobSubType?.JobSubTypeID, isNaN(Number(selectedSite?.SiteID)) ? null
        : Number(selectedSite
            ?.SiteID), isNaN(Number(selectedLocation
        ?.LocationID))
        ? null
        : Number(selectedLocation
            ?.LocationID), isNaN(Number(selectedSublocation
        ?.SubLocationID))
        ? null
        : Number(selectedSublocation
            ?.SubLocationID)), {
        onSuccess: () => {
            enqueueSnackbar(t("Request raised successfully, it will be added to the request list soon"), {
                variant: "success",
                onClose: () => closeSnackbar(),
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
            setDetails("");
            handleSelectedAssetChange(null);
            handleSelectedJobTypeChange(null);
            handleSelectedSiteChange(null);
            resetMutation();

            var newGuid: string = generateGuid();
            setGuid(newGuid);
            getPhotos(newGuid);
        },
        retry: false,
        onError: (error : {
            message: string
        }) => alert(error.message)
    });

    const handleSubmit = (event : FormEvent < HTMLFormElement >) => {
        event.preventDefault();
        if(!selectedSite){
            enqueueSnackbar(t("A site is required to raise a request."), {
                variant: "warning",
                onClose: () => closeSnackbar(),
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
            return;
        };

        if(!selectedLocation && (clientSettings?.LocationRequired && isLocationsForSite)){
            enqueueSnackbar(t("A location is required to raise a request."), {
                variant: "warning",
                onClose: () => closeSnackbar(),
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
            return;
        }

        resetMutation();
        createRequest();

        if(!navigator.onLine){
            resetMutation();
            enqueueSnackbar(t("You appear to be offline. Your request has been stored and will be raised once you reconnect to the internet"), {
                variant: "info",
                onClose: () => closeSnackbar(),
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
            setDetails("");
            handleSelectedAssetChange(null);
            handleSelectedJobTypeChange(null);
            handleSelectedSiteChange(null);
            setGuid(generateGuid);
            getPhotos("testtest");
        } 
    };

    const getPhotos = (guid : string) => {
        if (isNullOrUndefined(guid)) 
            return;

            getPhotosForCreateRequest(guid)
            .then(photoArray => {
                     const arrayMap = photoArray.map(photo => {
                        const blob = new Blob([photo.file]);
                        const blobUrl = URL.createObjectURL(blob);
                        const id = photo.id;
                        return { FirebaseStoragePath : blobUrl, id: id};
                    });
                    setRequestPhotos(arrayMap);
            });
    }

    const saveImage = async(selectorFiles : FileList | null) => {
        if (selectorFiles !== null) {
            for (let i = 0; i < selectorFiles.length; i++) {
                const photo : Blob = selectorFiles[i];
                const CompressionRatio = GetFileCompressionRatio(photo);

                loadImage(photo, (canvas: HTMLCanvasElement | HTMLImageElement | any) => {
                    canvas.toBlob(async (blob: Blob) => {
                        const arrayBuffer = await fileToArrayBuffer(blob as Blob);

                        await setPhotoForCreateRequest(arrayBuffer, guid)
                            .then(generatedGuid => {
                                getPhotos(guid);
                            })
                            .catch(err => console.error(err));
                    });
                }, {
                    maxWidth: 400,
                    maxHeight: 400,
                    orientation: true,
                    downsamplingRatio: CompressionRatio,
                    canvas: true
                },);
            }
        }
    };

    const openImageDialog = () => {
        const imageUploadButton = document.getElementById('image-upload');
        if (imageUploadButton !== null) {
            imageUploadButton.click();
        }
    };

    return {
        isLoading,
        details,
        selectedAsset,
        selectedJobType,
        selectedJobSubType,
        selectedSite,
        selectedLocation,
        selectedSublocation,
        handleDetailsChange,
        handleSelectedAssetChange,
        handleSelectedJobTypeChange,
        handleSelectedJobSubTypeChange,
        handleSelectedSiteChange,
        handleSelectedSiteChangeLazy,
        handleSelectedLocationChange,
        handleSelectedSublocationChange,
        handleSubmit,
        openImageDialog,
        saveImage,
        clientSettings,
        isLocationsForSite,
        requestPhotos,
        handleRaisedByChange,
        handleEmailChange,
        raisedBy,
        email,
        getPhotos,
        guid,
        userSettings
    };
};

export default useNewRequest;
