import {ChangeEvent, useEffect, useState} from "react";
import {useMutation} from "react-query";
import {useHistory, useLocation, useParams} from "react-router-dom";
import useAuth from "../auth/useAuth";
import {useSnackbar} from "notistack";

import logo from "../../assets/images/logo.png";
import dimoLogo from "../../assets/images/dimo-logo.png";

const useLogin = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {user, signin, signinWithMicrosoft, isClientExpired, isResourceNotFound} = useAuth();
    const history = useHistory();
    const location = useLocation < {
        from: {
            pathname: string
        }
    } > ();
    const {from} = location.state || {
        from: {
            pathname: "/"
        }
    };
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginImage, setLoginImage] = useState(logo);
    const {redirected} = useParams < {
        redirected: string | undefined
    } > ();

    const loginMutation = useMutation(() => signin(email, password), {
        onSuccess: () => history.replace(from)
    });
    const {isLoading: isLoginIn, isError: isLoginError, error: loginError, mutate: login} = loginMutation;

    useEffect(() => {
        if (user) {
            history.replace(from);
            
        };
    }, [user, from, history]);

    useEffect(() => {
      getLogo();
        if (redirected == "redirected") {
            enqueueSnackbar("Your request account has been successfully setup. Please login with your credent" +
                    "ials.", {
                variant: "success",
                onClose: () => closeSnackbar(),
                autoHideDuration: 11000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            });
        }
    }, []);

    const handleEmailChange = (event : ChangeEvent < HTMLTextAreaElement | HTMLInputElement >) => {
        setEmail(event.target.value);
    };

    const getLogo = async() => {
        var url = window.location.href;
		if(url.includes("appdem.trackplanfm.com"))
			setLoginImage(dimoLogo);
    };

    const handlePasswordChange = (event : ChangeEvent < HTMLTextAreaElement | HTMLInputElement >) => {
        setPassword(event.target.value);
    };

    const handleLoginWithMicrosoft = async() => {
        await signinWithMicrosoft();
    };

    return {
        email,
        password,
        handleEmailChange,
        handlePasswordChange,
        handleLoginWithMicrosoft,
        login,
        isLoginIn,
        isLoginError,
        loginError,
        isClientExpired,
        isResourceNotFound,
        redirected,
        loginImage
    };
};

export default useLogin;
