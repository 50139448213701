import { useTranslation } from "react-i18next";
import useAsyncPaginate from "../../hooks/async-paginate/useLocationPaginate";
import { AsyncPaginate } from 'react-select-async-paginate';
import '../../styles/new-request/new-request.css';
import useLocationPaginate from "../../hooks/async-paginate/useLocationPaginate";
import { Icon, IconButton, InputLabel } from "@material-ui/core";
import Location from "../../definitions/Location";

type AsyncPaginateProps = {
  handleSelectedLocationChange: (newOption: any) => void;
  selectedSiteID: string | null | undefined;
  selectedLocation: Location | null;
  clientFBID?: string;
};

const AsyncLocationDropdown = ({handleSelectedLocationChange, selectedSiteID, selectedLocation, clientFBID}: AsyncPaginateProps) => {
  const {
    loadLocations,
    locationFilter,
    locationRef,
    openLocationMenu,
    closeLocationMenu,
    locationMenuOpen,
    handleLocationChange,
    showKeyboard,
    locationValue,
  } = useLocationPaginate(selectedSiteID, handleSelectedLocationChange, selectedLocation, clientFBID);
  const { t } = useTranslation();

  return (
    <>
    <InputLabel style={{ marginBottom: 7, display: 'flex' }}>{t("Location")}</InputLabel>
    <div className="select-container">
    <div className="select-sub-container">
    <AsyncPaginate
        id="location-helper"
        value={locationFilter}
        selectRef={locationRef}
        onFocus={openLocationMenu}
        onBlur={closeLocationMenu}
        menuIsOpen={locationMenuOpen}
        onChange={handleLocationChange}
        blurInputOnSelect={true}
        loadOptions={loadLocations}
        loadingMessage={() => t("Loading Locations...")}
        noOptionsMessage={() => t("No Locations Found")}
        cacheUniqs={[locationFilter, locationValue, selectedSiteID]}
        isDisabled={!selectedSiteID}
        isSearchable={ true }
      />
  </div>
  {locationFilter != null ? <div className="clear-button-container">
  <div>
    <IconButton onClick={() => handleLocationChange(null)} className="clear-button">
      <Icon color="error">delete_forever</Icon>
    </IconButton>
  </div>
</div> : null}
</div>
</>
  );
};

export default AsyncLocationDropdown;
