import { Button, Dialog, DialogActions, DialogContent, Grid, Icon, Typography, Box } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'react-image';
import useDialog from '../../hooks/dialogue/useDialog';
import '../../styles/requests/request-photos.css';
import DocumentDetails from './DocumentDetails';
import ImageLoadingSpinner from './ImageLoadingSpinner';

type RequestPhotosProps = {
    photos: RequestPhoto[];
    isLoading: boolean;
    saveImage: (selectorFiles: FileList | null, requestFBID: string | null) => Promise<void>;
    requestID: string;
    deletePhoto: (photo: RequestPhoto, requestFBID: string) => Promise<void>;
};

const RequestCardPhotos = ({ photos, isLoading, saveImage, requestID, deletePhoto }: RequestPhotosProps) => {
    const { isDialogOpen, openDialog, closeDialog } = useDialog();
    const [selectedPhoto, setSelectedPhoto] = useState<RequestPhoto | null>(null);
    const { t } = useTranslation();

    const handlePhotoClick = (photo: RequestPhoto) => {
        setSelectedPhoto(photo);
        openDialog();
    };

    const deleteImage = (photo: RequestPhoto, requestFBID: string) => {
        deletePhoto(photo, requestFBID);
        closeDialog();
    };

    const openImageDialog = () => {
        const imageUploadButton = document.getElementById('image-upload');
        if (imageUploadButton !== null) {
            imageUploadButton.click();
        }
    };

    if (isLoading) {
        return (
            <>
                <Typography variant="h1" color="primary" className="loading-spinner-text">
                    {t('Loading')}
                </Typography>
            </>
        );
    }

    return (
        <>
            {photos.length > 0 && (
                <div className="card-shadow request-photos-card-container" style={{ padding: 0 }}>
                    <div className="request-photos-display">
                        <Grid container item spacing={1}>
                            {photos.map(photo => (
                                <Grid item xs="auto" key={photo.ID}>
                                    <Img
                                        className="request-photo"
                                        src={photo.FirebaseStoragePath || photo.AzureImage}
                                        loader={<ImageLoadingSpinner />}
                                        onClick={() => handlePhotoClick(photo)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            )}
            <Box padding={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    className="add-photo-button-requests"
                    onClick={() => openImageDialog()}
                    fullWidth
                >
                    <Icon>add_circle</Icon>&nbsp; {t('Add Photo')}
                </Button>
            </Box>

            <input
                id="image-upload"
                type="file"
                multiple
                onChange={e => saveImage(e.target.files, requestID)}
                style={{ display: 'none' }}
            />
            {isDialogOpen && selectedPhoto && (
                <Dialog open={isDialogOpen} onClose={closeDialog} PaperProps={{ className: 'photo-dialog' }}>
                    <DialogContent>
                        <Img
                            style={{ objectFit: 'fill', maxWidth: '100%', marginBottom: 25 }}
                            src={selectedPhoto.FirebaseStoragePath || selectedPhoto.AzureImage}
                        />
                        <DocumentDetails
                            Filename={selectedPhoto.Filename}
                            UploadedBy={selectedPhoto.UploadedBy}
                            Source={selectedPhoto.Source}
                            DateCreated={selectedPhoto.UploadedDate}
                        />
                    </DialogContent>
                    <DialogActions>
                        {selectedPhoto.UploadedBy === undefined ? null : (
                            <Button
                                onClick={() => deleteImage(selectedPhoto, requestID)}
                                color="primary"
                                style={{ backgroundColor: '#e34230', color: 'white' }}
                            >
                                {t('Delete')}
                            </Button>
                        )}
                        <Button onClick={closeDialog} color="primary">
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default RequestCardPhotos;
