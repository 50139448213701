import { Grid } from "@material-ui/core";
import ErrorDisplay from "../../components/shared/ErrorDisplay";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import NoResults from "../../components/shared/NoResults";
import PaginationButton from "../../components/shared/PaginationButton";
import SublocationCard from "../../components/sublocations/SublocationCard";
import useSublocations from "../../hooks/sublocations/useSublocations";
import { useTranslation } from 'react-i18next';

const Sublocations = () => {
  const {
    collapsedSublocationID,
    setCollapsedSublocationID,
    sublocations,
    isLoading,
    isError,
    error,
    refetch,
    isEndOfList,
    handleLoadMore,
  } = useSublocations();
  const { t, i18n } = useTranslation();

  if (isLoading) {
    return <LoadingSpinner text={t("Loading Sublocations")}/>;
  }

  if (isError) {
    return (
      <ErrorDisplay errorMessage={(error as any).message} retry={refetch} />
    );
  }

  if (sublocations.length === 0) {
    return <NoResults itemName={t("Sublocations")} />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {sublocations.map((sublocation) => (
        <Grid
          item
          key={sublocation.ID}
          onClick={() =>
            setCollapsedSublocationID(
              sublocation.ID === collapsedSublocationID ? "" : sublocation.ID
            )
          }
        >
          <SublocationCard
            sublocation={sublocation}
            isCollapseOpen={sublocation.ID === collapsedSublocationID}
          />
        </Grid>
      ))}
      <PaginationButton
        width="75vw"
        isEndOfList={isEndOfList}
        itemName={t("Sublocations")}
        handleLoadMore={handleLoadMore}
      />
    </Grid>
  );
};

export default Sublocations;
