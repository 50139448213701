import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";

const useForgottenPassword = () => {
  const { user, sendForgottenPasswordLink: sendPasswordLink } = useAuth();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const { from } = location.state || { from: { pathname: "/" } };
  const [email, setEmail] = useState("");
  const [forgottenPasswordError, setForgottenPasswordError] = useState<
    string | null
  >(null);
  const [forgottenPasswordSuccess, setForgottenPasswordSuccess] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (user) {
      history.replace(from);
    }
  }, [user, from, history]);

  const handleEmailChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleNavigateBack = () => {
    history.replace(from);
  };

  const sendForgottenPasswordLink = () => {
    setForgottenPasswordError(null);
    setForgottenPasswordSuccess(null);
    sendPasswordLink(email)
      .then(() => {
        setForgottenPasswordSuccess(
          "An email has been sent out successfully to the email address you have entered. Please check your inbox and follow the instructions."
        );
      })
      .catch((error) => setForgottenPasswordError(error.message));
  };

  return {
    email,
    forgottenPasswordError,
    forgottenPasswordSuccess,
    handleEmailChange,
    handleNavigateBack,
    sendForgottenPasswordLink,
  };
};

export default useForgottenPassword;
