import { Box, Grid } from '@material-ui/core';
import AssetCard from '../../components/assets/AssetCard';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import NoResults from '../../components/shared/NoResults';
import PaginationButton from '../../components/shared/PaginationButton';
import SearchFilter from '../../components/shared/SearchFilter';
import useAssets from '../../hooks/assets/useAssets';
import { useTranslation } from 'react-i18next';

const Assets = () => {
    const {
        assets,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore,
        searchFunction,
        selectedSite,
        selectedLocation,
        selectedSubLocation,
        selectedAssetClass,
        selectedSubClass,
        clearFilter,
        collapsedAssetID,
        setCollapsedAssetID,
        textSearch,
    } = useAssets();
    const { t, i18n } = useTranslation();

    if (isLoading) {
        return <LoadingSpinner text={t('Loading Assets')} />;
    }

    if (isError) {
        return (
            <>
                <ErrorDisplay errorMessage={(error as any).message} retry={refetch} />
                <SearchFilter
                    label={t('Assets Search')}
                    searchFunction={searchFunction}
                    clearFilter={clearFilter}
                    site={selectedSite}
                    location={selectedLocation}
                    sublocation={selectedSubLocation}
                    assetClass={selectedAssetClass}
                    subClass={selectedSubClass}
                    siteType={null}
                    region={null}
                    contract={null}
                    textInput={textSearch}
                    displayTextSearch={true}
                />
            </>
        );
    }

    if (assets.length === 0) {
        return (
            <>
                <NoResults itemName={t('Assets')} />
                <SearchFilter
                    label={t('Assets Search')}
                    searchFunction={searchFunction}
                    clearFilter={clearFilter}
                    site={selectedSite}
                    location={selectedLocation}
                    sublocation={selectedSubLocation}
                    assetClass={selectedAssetClass}
                    subClass={selectedSubClass}
                    siteType={null}
                    region={null}
                    contract={null}
                    textInput={textSearch}
                    displayTextSearch={true}
                />
            </>
        );
    }

    return (
        <div>
            <Grid container justifyContent="center" alignItems="center" direction="column">
                {assets.map(asset => (
                    <Grid
                        item
                        key={asset.ID}
                        style={{ width: '100%' }}
                        onClick={() => setCollapsedAssetID(asset.ID === collapsedAssetID ? '' : asset.ID)}
                    >
                        <Box marginTop={1}>
                            <AssetCard asset={asset} isCollapseOpen={asset.ID === collapsedAssetID} />
                        </Box>
                    </Grid>
                ))}
                <Grid item>
                    <PaginationButton
                        width="100%"
                        isEndOfList={isEndOfList}
                        itemName={t('Assets')}
                        handleLoadMore={handleLoadMore}
                    />
                </Grid>
            </Grid>
            <SearchFilter
                label={t('Assets Search')}
                searchFunction={searchFunction}
                clearFilter={clearFilter}
                site={selectedSite}
                location={selectedLocation}
                sublocation={selectedSubLocation}
                assetClass={selectedAssetClass}
                subClass={selectedSubClass}
                siteType={null}
                region={null}
                contract={null}
                textInput={textSearch}
                displayTextSearch={true}
            />
        </div>
    );
};

export default Assets;
