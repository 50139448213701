import { useCallback, useState } from "react";

const STEP = 25;

const usePagination = () => {
  const [numberOfItems, setNumberOfItems] = useState(STEP);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const handleLoadMore = () => {
    setScrollY(window.scrollY);
    setNumberOfItems(numberOfItems + STEP);
  };

  const scrollToPreviousPosition = useCallback(() => {
    window.scrollTo(0, scrollY);
  }, [scrollY]);

  return {
    numberOfItems,
    isEndOfList,
    setIsEndOfList,
    handleLoadMore,
    scrollToPreviousPosition,
  };
};

export default usePagination;
