import firebase from "firebase/app";
import { useCallback, useEffect, useState } from "react";
import { User } from "../../definitions/User";
import i18n from "../../i18n";
import { getUserClientUID, doesUserExist, hasClientExpired } from "../../utils/firebase";

const useProvideAuth = () => {
    const [user,
        setUser] = useState<User | null>(null);
    const [isCheckingDetails,
        setIsCheckingDetails] = useState(true);
    const [isResourceNotFound,
        setIsResourceNotFound] = useState(false);
    const [isClientExpired,
        setIsClientExpired] = useState(false);

    const setUserWithClientUID = (user: firebase.User) => {
        if (user && user.email !== "dummyuser@test.com") {
            const onResult = async (queryResult: firebase.firestore.DocumentData) => {
                var isExpired = undefined;
                if (!queryResult.empty) {
                    isExpired = await hasClientExpired(queryResult.docs[0].data().ClientUID);
                }
                if (queryResult.empty) {
                    setIsResourceNotFound(true);
                } else if (queryResult.docs[0].data().IsDisabled === true || queryResult.docs[0].data().IsDeleted === true) {
                    console.log("User is disabled, deleted.");
                    setIsResourceNotFound(true);
                } else if (isExpired === true) {
                    setIsClientExpired(true);
                } else {
                    setUser({
                        ...user,
                        ClientUID: queryResult
                            .docs[0]
                            .data()
                            .ClientUID,
                        CanAccessAllSites: queryResult
                            .docs[0]
                            .data()
                            .CanAccessAllSites,
                        CanViewAllRequests: queryResult
                            .docs[0]
                            .data()
                            .CanViewAllRequests,
                        CanViewAssets: queryResult
                            .docs[0]
                            .data()
                            .CanViewAssets,
                        CanViewRequests: queryResult
                            .docs[0]
                            .data()
                            .CanViewRequests,
                        CanViewSites: queryResult
                            .docs[0]
                            .data()
                            .CanViewSites,
                        email: queryResult
                            .docs[0]
                            .data()
                            .email,
                        Name: queryResult
                            .docs[0]
                            .data()
                            .Name,
                        id:
                            queryResult
                                .docs[0]
                                .data().ID
                    });
                    i18n.changeLanguage(queryResult.docs[0].data().Language ?? queryResult.docs[0].data().ClientLanguage);
                }
                setTimeout(() => setIsCheckingDetails(false), 50);
            };
            getUserClientUID(user, onResult);
        }
    };

    useEffect(() => {
        const unsubscribe = firebase
            .auth()
            .onAuthStateChanged((user) => {
                if (user) {
                    setUserWithClientUID(user);
                } else {
                    setUser(null);
                    setIsCheckingDetails(false);
                }
            });
        return () => unsubscribe();
    }, []);

    const signin = (email: string, password: string) => {
        setIsResourceNotFound(false);
        setIsClientExpired(false);
        setIsCheckingDetails(true);
        return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((response) => {
                response.user && setUserWithClientUID(response.user);
            });
    };

    const signinAsGuest = useCallback(() => {
        return firebase
            .auth()
            .signInWithEmailAndPassword("dummyuser@test.com", "stage5");
    }, []);

    const signinWithMicrosoft = async() => {
        const microsoftProvider = new firebase
            .auth
            .OAuthProvider("microsoft.com");
        window
            .history
            .replaceState(null, "", "/");

        await firebase.auth().signInWithPopup(microsoftProvider).then(async (user) => {
            const fbUser = user.user as firebase.User;
            const userExists = await doesUserExist(fbUser.uid);
            if (!userExists) {
                window.alert("User not found. Please check login details and try again.");
                fbUser.delete();
                firebase.auth().signOut();
            } else {
                console.log("user does not exist");
                setUserWithClientUID(fbUser);
            }
        }).catch(error => {
            if(error.code === 'auth/account-exists-with-different-credential') {
                var pendingCred = error.credential;
                var existingEmail = error.email;

                firebase.auth().fetchSignInMethodsForEmail(existingEmail).then(methods => {
                    if(methods[0] === 'password') {
                        var password = window.prompt('Please Enter the Password for your Request App Login.  This will only be needed once.') as string;

                        firebase.auth().signInWithEmailAndPassword(existingEmail, password).then(user => {
                            const fbUser = user.user as firebase.User;
                            return fbUser.linkWithCredential(pendingCred);
                        }).then(async(user) => {
                            const fbUser = user.user as firebase.User;
                            const userExists = await doesUserExist(fbUser.uid);
                            if (!userExists) {
                                window.alert("User not found. Please check login details and try again.");
                                firebase.auth().signOut();
                            } else
                                setUserWithClientUID(fbUser);
                        });
                        return;
                    }

                    // Other Providers will be handled here
                })
            }
        });
    };

    const sendForgottenPasswordLink = (email: string) => {
        return firebase
            .auth()
            .sendPasswordResetEmail(email, { url: "https://request.trackplanfm.com/" });
    };

    const updatePassword = (newPassword: string) => {
        return firebase
            .auth()
            .currentUser
            ?.updatePassword(newPassword);
    };

    const reauthenticateWithCredential = (currentPassword: string) => {
        const credential = firebase
            .auth
            .EmailAuthProvider
            .credential(user
                ?.email as string, currentPassword);
        return firebase
            .auth()
            .currentUser
            ?.reauthenticateWithCredential(credential);
    };

    const signout = () => {
        setIsResourceNotFound(false);
        setIsClientExpired(false);
        return firebase
            .auth()
            .signOut()
            .then(() => {
                setUser(null);
            });
    };

    return {
        user,
        isCheckingDetails,
        isClientExpired,
        isResourceNotFound,
        signin,
        signinAsGuest,
        signinWithMicrosoft,
        sendForgottenPasswordLink,
        updatePassword,
        reauthenticateWithCredential,
        signout
    };
};

export default useProvideAuth;
