import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import allLanguages from "./allLanguages.json";

const resources = allLanguages;

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: "English", 
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;