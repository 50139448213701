import {
    Card,
    CardActions,
    CardContent,
    Collapse,
    Grid,
    Icon,
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Box,
    Button,
} from '@material-ui/core';
import clsx from 'clsx';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

import Request from '../../definitions/Request';
import '../../styles/requests/request-card.css';
import { getColorFromStatus, getIconFromStatus } from '../../utils/status';
import RequestCardPhotos from '../shared/RequestCardPhotos';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import ReactShowMoreTextRipple from '../shared/ReactShowMoreTextRipple';
import { unixToDateString } from '../../utils/Times';

type RequestCardProps = {
    request: Request;
    isCollapseOpen: boolean;
    requestPhotos: RequestPhoto[];
    isPhotosLoading: boolean;
    setCollapsedRequestID: React.Dispatch<React.SetStateAction<string>>;
    saveImage: (selectorFiles: FileList | null, requestFBID: string | null) => Promise<void>;
    deletePhoto: (photo: RequestPhoto, requestFBID: string) => Promise<void>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        wrapper: {
            width: '100%',
        },
        wrapperInner: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    }),
);

const ExpandPhotoContainerBox = ({ children }: { children?: React.ReactNode }) => (
    <Box display="flex" justifyContent="center">
        {children}
    </Box>
);

const RequestCard = ({
    request,
    isCollapseOpen,
    requestPhotos,
    isPhotosLoading,
    setCollapsedRequestID,
    saveImage,
    deletePhoto,
}: RequestCardProps): JSX.Element => {
    const statusColor = getColorFromStatus(request.RequestStatus);
    const statusIcon = getIconFromStatus(request.RequestStatus);
    const { t } = useTranslation();
    const classes = useStyles();
    const requestFields = [
        {
            name: t('Details'),
            value: request.Details,
        },
        {
            name: t('Site'),
            value: request.Site,
        },
        {
            name: t('Location'),
            value: request.Location,
        },
        {
            name: t('Raised By'),
            value: request.RaisedBy,
        },
        {
            name: t('Created'),
            // replace extra spaces with a single space
            value: typeof request.Created === 'number' ? unixToDateString(request.Created) : request.Created,
        },
    ];
    return (
        <Card className="request-card" style={{ border: `4px solid ${statusColor}`, width: '100%', margin: 'auto' }}>
            <CardContent className="request-card-content">
                <Grid
                    container
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                    className="request-card-header-container"
                >
                    <Grid
                        item
                        className="request-number-container"
                        style={{
                            backgroundColor: statusColor,
                        }}
                    >
                        <Typography className="request-card-header-text">{request.RequestNumber}</Typography>
                    </Grid>
                    {request.isPhotosForRequest && (
                        <Grid item zeroMinWidth>
                            <CropOriginalIcon color="primary" />
                        </Grid>
                    )}
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className="request-status-container"
                        style={{
                            backgroundColor: statusColor,
                        }}
                    >
                        <Grid item zeroMinWidth>
                            <Typography noWrap className="request-card-header-text">
                                {t(request.RequestStatus)}
                            </Typography>
                        </Grid>
                        {statusIcon && (
                            <Grid item className="request-status-icon-container">
                                <Icon className="request-card-header-text">{statusIcon}</Icon>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container direction="column" className="request-card-fields-container">
                    {requestFields.map(requestField => (
                        <Grid key={requestField.name} container className="request-field-container" wrap="nowrap">
                            <Typography className="request-field-name">{requestField.name}:&nbsp;</Typography>
                            <ReactShowMoreTextRipple
                                anchorClass="request-field--show-more-anchor-class"
                                className="MuiTypography-root MuiTypography-body1 MuiTypography-alignLeft"
                                stringContent={requestField.value}
                                more={<ExpandMore className="expand" />}
                            >
                                <Typography align="left">{requestField.value}</Typography>
                            </ReactShowMoreTextRipple>
                        </Grid>
                    ))}
                </Grid>
                <CardActions>
                    <Button
                        onClick={() => setCollapsedRequestID(isCollapseOpen ? '' : request.ID)}
                        aria-expanded={isCollapseOpen}
                        aria-label="show more"
                        startIcon={
                            <ExpandMore
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: isCollapseOpen,
                                })}
                            />
                        }
                    >
                        {t('Add Photo')}
                    </Button>
                </CardActions>
                <Collapse
                    in={isCollapseOpen}
                    timeout="auto"
                    unmountOnExit
                    classes={{
                        wrapper: classes.wrapper,
                        wrapperInner: classes.wrapperInner,
                    }}
                    style={{ width: '100%' }}
                    component={ExpandPhotoContainerBox}
                >
                    <RequestCardPhotos
                        photos={requestPhotos}
                        isLoading={isPhotosLoading}
                        saveImage={saveImage}
                        requestID={request.ID}
                        deletePhoto={deletePhoto}
                    />
                </Collapse>
            </CardContent>
        </Card>
    );
};

export default RequestCard;
