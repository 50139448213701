import firebase from "firebase/app";
import {useEffect, useRef, useState} from "react";
import { getSearchAssetsPaginated } from "../../utils/firebase";
import useAuth from "../auth/useAuth";
import { isNullOrUndefined } from "util";

const useAssetPaginate = (handleSelectedAssetChange: any, siteID: string, locationID: string, sublocationID: string, clientFBID: string, selectedAsset: Asset | null) => {
    const [label, setLabel] = useState("");
    const [assetValue, setAssetValue] = useState("");
    const [loadedAssets, setLoadedAssets] = useState([]);
    const [lastLoadedAsset, setLastLoadedAsset] = useState<any>();
    const [assetsPerPage, setAssetsPerPage] = useState(50);
    const [endAsset, setEndAsset] = useState(false);
    const [assetFilter, setAssetFilter] = useState <{value: number; label: string;} | null>();
    const [assetMenuOpen, setAssetMenuOpen] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const assetRef = useRef<HTMLInputElement>();
    const {user} = useAuth();

    useEffect(() => { 
        if(siteID != selectedAsset?.SiteFBID)
            clearAsset();
    }, [siteID, locationID, sublocationID]);

    useEffect(() => {
        if(selectedAsset?.AssetID != null){
            setAssetFilter({value: selectedAsset.AssetID, label: selectedAsset?.AssetName || ""});
        }
      }, [selectedAsset]);

    const loadAssets = async(assetName: string, loadedOptions: any) => {
        var lastLoadedAssetCached: any = lastLoadedAsset;
        var assetValueCached: any = assetValue;
        if(assetName !== assetValue) {
            lastLoadedAssetCached = null;
            assetValueCached = assetName;
            await setLoadedAssets([]);
            await setLastLoadedAsset(null);
            await setAssetValue(assetName);
            loadedOptions = [];
        }

        return new Promise((resolve) => {
            getSearchAssetsPaginated(assetsPerPage, siteID, locationID, sublocationID, assetValueCached, lastLoadedAssetCached, clientFBID).get().then(docs => {
                if(!docs.empty) {
                    let assets: any = [];
                    docs.forEach(function(asset) {
                        let assetValue = asset.data();
                        assetValue.ID = asset.id;
                        
                        const assetData = {
                            value: assetValue,
                            label: asset.data().AssetName
                        }
                        assets.push(assetData);
                    });
                    setLoadedAssets(assets);
                    setLastLoadedAsset(docs.docs[docs.docs.length - 1])
                    return resolve({
                        options: assets,
                        hasMore: !endAsset
                    });
                } else {
                    setEndAsset(true);
                    return resolve({
                        options: [],
                        hasMore: false
                    });
                }
            })
        })
	};

    const openAssetMenu = () => {
        setAssetMenuOpen(true);
        if(!(assetRef.current === null || assetRef.current === undefined)){
            assetRef.current.focus();
        }
            
    };

    const closeAssetMenu = () => setAssetMenuOpen(false);

    const clearAsset = () => {
        setLastLoadedAsset(null);
        setAssetFilter(null);
        setLoadedAssets([]);
        setAssetValue("");
    };

    const handleAssetChange = (asset: any) => {
        setLastLoadedAsset(null);
        handleSelectedAssetChange(asset);
        if(asset == null){
            clearAsset();
            return;     
        }
        setAssetFilter({value: asset.value, label: asset.label});
    };
	
    return {
        loadAssets,
        assetFilter,
        assetRef,
        openAssetMenu,
        closeAssetMenu,
        assetMenuOpen,
        handleAssetChange,
        showKeyboard,
        assetValue
    };
};

export default useAssetPaginate;
