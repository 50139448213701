import { Button, Dialog, DialogActions, DialogContent, Grid, Icon, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Img from 'react-image';
import useDialog from '../../hooks/dialogue/useDialog';
import '../../styles/requests/request-photos.css';
import DocumentDetails from '../shared/DocumentDetails';
import ImageLoadingSpinner from '../shared/ImageLoadingSpinner';
import {deletePhotosByStoragePath} from "../../utils/IndexDb";


type RequestPhotosProps = {
	photos: RequestPhoto[];
	getPhotos: any;
	guid: string | null | undefined;
};

const RequestPhotos = ({ photos, getPhotos, guid }: RequestPhotosProps) => {
	const { isDialogOpen, openDialog, closeDialog } = useDialog();
	const [selectedPhoto, setSelectedPhoto] = useState<RequestPhoto | null>(null);

	const handlePhotoClick = (photo: any) => {
		setSelectedPhoto(photo);
		openDialog();
	};

	const deletePhoto = (photo: RequestPhoto) => {
		deletePhotosByStoragePath(photo.id);
		getPhotos(guid);
		closeDialog();
	};

	if (photos.length === 0) {
		return (<></>);
	}

	return (
		<>
			<div className="card-shadow request-photos-container" style={{ padding: 0 }}>
				{/* <div className="request-photos-header">
					<Typography style={{ color: 'white' }}>Request Photos</Typography>
				</div> */}
				<div className="request-photos-display">
					<Grid container item spacing={1} style={{margin: "5px"}}>
						{photos.map(photo => (
							<Grid item xs="auto" key={photo.ID}>
								<Img
									className="request-photo"
									src={photo.FirebaseStoragePath}
									loader={<ImageLoadingSpinner />}
									onClick={() => handlePhotoClick(photo)}
								/>
							</Grid>
						))}
					</Grid>
				</div>
			</div>
			{isDialogOpen && selectedPhoto && (
				<Dialog open={isDialogOpen} onClose={closeDialog} PaperProps={{ className: 'photo-dialog' }}>
					<DialogContent>
						<Img style={{ objectFit: 'fill', maxWidth: '100%', marginBottom: 25 }} src={selectedPhoto.FirebaseStoragePath} />
						<DocumentDetails
							Filename={selectedPhoto.Filename}
							UploadedBy={selectedPhoto.UploadedBy}
							Source={selectedPhoto.Source}
							DateCreated={selectedPhoto.UploadedDate}
						/>
					</DialogContent>
					<DialogActions>
					{/* <Button
					style={{ backgroundColor: 'var(--light-red)', color: 'white' }}
					variant="outlined"
					color="inherit"
					size="small"
					onClick={() => handlePhotoClick({})}
				>
											<Icon style={{ color: 'white' }} fontSize="small">
												clear
											</Icon>
											Remove
										</Button> */}
						<Button onClick={() => deletePhoto(selectedPhoto)} color="primary" style={{ backgroundColor: '#e34230', color: 'white' }}>
							Delete
						</Button>
						<Button onClick={closeDialog} color="primary" >
							Close
						</Button>
						
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default RequestPhotos;
