import firebase from "firebase/app";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import Location from "../../definitions/Location";
import useAuth from "../../hooks/auth/useAuth";
import {getLocationsOfSite} from "../../utils/firebase";
import usePagination from "../pagination/usePagination";

const useLocations = () => {
    const {user} = useAuth();
    const {siteID} = useParams < {
        siteID: string
    } > ();
    const {numberOfItems, isEndOfList, setIsEndOfList, handleLoadMore, scrollToPreviousPosition} = usePagination();
    const [collapsedLocationID,
        setCollapsedLocationID] = useState("");
    const [locations,
        setLocations] = useState < Location[] > ([]);

    useEffect(scrollToPreviousPosition, [locations, scrollToPreviousPosition]);

    const onSuccess = (result : firebase.firestore.QuerySnapshot < firebase.firestore.DocumentData >) => {
        if (result.docs.length < numberOfItems) {
            setIsEndOfList(true);
        }
        const locations = result
            .docs
            .map((doc) => {
                const location = doc.data();
                location.ID = doc.id;
                return location as Location;
            });
        setLocations(locations);
    };

    const {isLoading, isError, error, refetch} = useQuery([
        `${user
            ?.ClientUID}-${siteID}-locations`,
        numberOfItems
    ], () => getLocationsOfSite(siteID, numberOfItems), {onSuccess, cacheTime: 0});

    return {
        collapsedLocationID,
        setCollapsedLocationID,
        locations,
        isLoading,
        isError,
        error,
        refetch,
        isEndOfList,
        handleLoadMore
    };
};

export default useLocations;
