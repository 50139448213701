import { CircularProgress, Typography } from "@material-ui/core";
import "../../styles/shared/checking-details-spinner.css";

const CheckingDetailsSpinner = () => (
  <div className="checking-details-spinner-container">
    <Typography
      variant="h1"
      color="primary"
      className="checking-details-spinner-text"
    >
      Checking your details
    </Typography>
    <CircularProgress size={50} color="secondary" />
  </div>
);

export default CheckingDetailsSpinner;
