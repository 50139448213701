// DaysJS Imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

// Utils
import { isNullOrUndefined } from 'util';

export const parseDateForDateTimeFields = function(date: number | null) {
	var localIso = !isNullOrUndefined(date) ? dayjs(date).format() : dayjs().format();
	localIso = localIso.slice(0, localIso.length - 9);
	return localIso;
};

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);


//converts unix time to date with offset
export const unixToDateString = (unixTime: number, excludeTime?: boolean): string => {
	if (unixTime === 0 || isNullOrUndefined(unixTime)) return '';

	const format = isNullOrUndefined(excludeTime) ? 'lll' : excludeTime ? 'll' : 'lll';
	const date = dayjs(unixTime).format(format);
	return date;
};

//converts unix to time string with offset
export const unixToTimeString = (unixTime: number): string => {
	return dayjs(unixTime).format('LT');
};

//checks if a date is todays date
export const isTodaysDate = (unixTime: number) => {
	const passedDate = dayjs(unixTime);
	const today = dayjs();

	return !passedDate.isAfter(today, 'date') && !passedDate.isBefore(today, 'date');
};

//parses date and returns date sting
export const parseDate = (questionType: string, date?: string | null): string => {
	if (date === '' || date === 'NaN' || date === null || date === undefined) return '';
	let localIso = dayjs(parseInt(date)).format();
	if (questionType === 'DateTime') {
		localIso = localIso.slice(0, localIso.length - 9);
		return localIso;
	} else {
		localIso = localIso.slice(0, localIso.length - 15);
		return localIso;
	}
};

export const parseDate2 = (questionType: string, date?: string | null): string => {
	if (date === '' || date === 'NaN' || date === null || date === undefined) return '';
	let localIso = dayjs(parseInt(date)).format();
	if (questionType === 'DateTime') {
		localIso = localIso.slice(0, localIso.length - 9);
		let re = /T/gi;
		localIso = localIso.replace(re," ")
		return localIso;
	} else {
		localIso = localIso.slice(0, localIso.length - 15);
		return localIso;
	}
};

//converts string to unix utc
export const stringtoUnixUTC = (time: string, isDate?: boolean): number => {
	let date = dayjs(time);

	//if is date only, need to addoffset back to get actual day
	if (isDate === true) {
		let dateUnix = date.unix() * 1000;
		let dateOffset = date.utcOffset() * 60000;
		const combinedWithOffset = dateUnix + dateOffset;

		return combinedWithOffset;
	} else {
		return date.utc().unix() * 1000;
	}
};
