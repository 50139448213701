import { useTranslation } from "react-i18next";
import useAsyncPaginate from "../../hooks/async-paginate/useAsyncPaginate";
import { AsyncPaginate } from 'react-select-async-paginate';
import '../../styles/new-request/new-request.css';
import { Icon, IconButton, InputLabel } from "@material-ui/core";

type AsyncPaginateProps = {
  handleSelectedSiteChange: (newOption: any) => void;
  selectedSite: Site | null;
  clientFBID?: string;
};

const AsyncPaginateDropdown = ({handleSelectedSiteChange, selectedSite, clientFBID}: AsyncPaginateProps) => {
  const {
    loadSites,
    siteFilter,
    siteRef,
    openSiteMenu,
    closeSiteMenu,
    siteMenuOpen,
    handleSiteChange,
    showKeyboard,
    siteValue,
  } = useAsyncPaginate(handleSelectedSiteChange, selectedSite, clientFBID);
  const { t } = useTranslation();

  return (
    <>
    <InputLabel style={{ marginBottom: 7, display: 'flex' }}>{t("Site")}</InputLabel>
    <div className="select-container">
    <div className="select-sub-container">
    <AsyncPaginate
        isRequired={true}
        required={true}
        id="site-helper"
        value={siteFilter}
        selectRef={siteRef}
        onFocus={openSiteMenu}
        onBlur={closeSiteMenu}
        menuIsOpen={siteMenuOpen}
        onChange={handleSiteChange}
        blurInputOnSelect={true}
        loadOptions={loadSites}
        loadingMessage={() => t("Loading Sites...")}
        noOptionsMessage={() => t("No Sites Found")}
        cacheUniqs={[siteFilter, siteValue]}
        isSearchable={ true }
								/>
  </div>
  {siteFilter != null ? <div className="clear-button-container">
  <div>
    <IconButton onClick={() => handleSiteChange(null)} className="clear-button">
      <Icon color="error">delete_forever</Icon>
    </IconButton>
  </div>
</div> : null}
  </div>
  </>
  );
};

export default AsyncPaginateDropdown;
