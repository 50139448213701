import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  MeetingRoom,
  NewReleases,
} from "@material-ui/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../../styles/sublocations/sublocation-card.css";

type SublocationCardProps = {
  isCollapseOpen: boolean;
  sublocation: Sublocation;
};

const SublocationCard = ({
  isCollapseOpen,
  sublocation,
}: SublocationCardProps) => {
  const history =
    useHistory<{ site: Site; location: Location; sublocation: Sublocation }>();
  const { site, location } = history.location.state || {};
  const { t, i18n } = useTranslation();

  const handleRaiseRequestForSublocation = () => {
    history.push("/new-request", { site, location, sublocation });
  };

  useEffect(() => {
    if (!site || !location) {
      history.push("/sites");
    }
  }, [site, location, history]);

  return (
    <List className="sublocation-card">
      <ListItem>
        <ListItemIcon>
          <MeetingRoom />
        </ListItemIcon>
        <ListItemText
          primary={sublocation.SubLocationName}
          className="sublocation-name"
        />
        {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
        <Button
          fullWidth
          variant="outlined"
          endIcon={<NewReleases />}
          className="raise-request-for-sublocation-button"
          onClick={handleRaiseRequestForSublocation}
        >
          {t("Raise request for sublocation")}
        </Button>
      </Collapse>
    </List>
  );
};

export default SublocationCard;
