import {Button} from "@material-ui/core";
import "../../styles/shared/pagination-button.css";

type PaginationButtonProps = {
    width: string;
    isEndOfList: boolean;
    itemName: string;
    handleLoadMore: () => void;
};

const PaginationButton = ({width, isEndOfList, itemName, handleLoadMore} : PaginationButtonProps) => (
    <Button
        color="primary"
        variant="contained"
        className="pagination-button"
        style={{
        width
    }}
        onClick={handleLoadMore}
        disabled={isEndOfList}>
        {isEndOfList
            ? `All ${itemName} loaded`
            : `Load more ${itemName}`}
    </Button>
);

export default PaginationButton;
